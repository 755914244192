import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useOrganization } from "@clerk/clerk-react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import useStyles from "./styles";
import Chip from "../../../components/general/Chip";
import Button from "../../../components/button";
import Searchbar from "../../../components/search-bar";
import PopoverMenu from "../../../components/popover-menu";
import ViewWrapper from "../../../components/view-wrapper";
import ConditionalProtect from "../../../components/conditional-protect";
import CollaborationCaseFormModal from "../../../components/collaboration-case-form-modal";
import { sendDealShareEmail } from "../../../apis/collaboration/base";
import {
  resetConfirmModalPropsAction,
  setConfirmModalPropsAction,
  setDeleteModalPropsAction,
} from "../../../utils/redux/slices";
import {
  useAPI,
  useAppSelector,
  useLocalStorage,
  useSearchBar,
} from "../../../utils/hooks";
import {
  cn,
  getStatusChipFillColor,
  sortArrayOfObjects,
  trimString,
} from "../../../utils/helpers";
import {
  IDealCase,
  ISelectOption,
  ITableSort,
  ServerPaginatedResponse,
  ICollaborationCase,
  ICollaborationCaseForm,
  ICollaborationCaseFormErrors,
  IPersonalAccountNavBackButtonId,
} from "../../../interfaces";
import {
  CREATION_DEAL_STATUS,
  DEAL_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_TYPE,
} from "../../../constants";

const columns = [
  { id: "name", label: "Shared Case Name", minWidth: 170, align: "left" },
  { id: "structure", label: "Deal Structure", minWidth: 100, align: "left" },
  {
    id: "tax_credit_structure",
    label: "Tax Credit Structure",
    minWidth: 100,
    align: "left",
  },
  {
    id: "tax_credit_type",
    label: "Tax Credit Type",
    minWidth: 100,
    align: "left",
  },
  { id: "status", label: "Status", minWidth: 50, align: "left" },
  { id: "actions", label: "Actions", minWidth: 50, align: "right" },
];

interface IProps {
  getCollaborationSharedCases: (
    uuId: string,
  ) => Promise<ServerPaginatedResponse<ICollaborationCase[]>>;
  createCollaborationSharedCase: (
    uuid: string,
    form: ICollaborationCaseForm,
  ) => Promise<ICollaborationCase>;
  updateCollaborationSharedCase: (
    uuid: string,
    caseId: string,
    form: ICollaborationCaseForm,
  ) => Promise<ICollaborationCase>;
  getDealCases: (
    dealUuid: string,
  ) => Promise<ServerPaginatedResponse<IDealCase[]>>;
  deleteDeal: (dealUuid: string) => Promise<boolean>;
}

export default function CommonCollaborationCases({
  getCollaborationSharedCases,
  createCollaborationSharedCase,
  updateCollaborationSharedCase,
  getDealCases,
  deleteDeal,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { organization } = useOrganization();

  const navigate = useNavigate();
  const { collaborationUuid } = useParams();

  const dispatch = useDispatch();
  const { currentCollaboration } = useAppSelector(
    (state) => state.collaboration,
  );
  const {
    searchString: searchTerm,
    onSearchStringChange: onSearchTermChange,
    handleClearSearchString: handleClearSearchTerm,
  } = useSearchBar();

  const [personalAccountNavBackButtonIds, setPersonalAccountNavBackButtonIds] =
    useLocalStorage<IPersonalAccountNavBackButtonId[]>(
      "personal-account-nav-back-btn",
      [],
    );

  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [tableSort, setTableSort] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });
  const [selectedCaseId, setSelectedCaseId] = React.useState<number | null>(
    null,
  );

  const [isAddCaseModalOpen, setIsAddCaseModalOpen] =
    React.useState<boolean>(false);
  const [isEditFormModalOpen, setIsEditFormModalOpen] =
    React.useState<boolean>(false);
  const [sharedCases, setSharedCases] = React.useState<ICollaborationCase[]>(
    [],
  );
  const [form, setForm] = React.useState<ICollaborationCaseForm>({
    case: null,
    name: null,
  });

  const handleSort = (orderBy: string) => {
    if (orderBy === tableSort.orderBy) {
      setTableSort({
        orderBy,
        order: tableSort.order === "asc" ? "desc" : "asc",
      });
    } else {
      setTableSort({
        orderBy,
        order: "asc",
      });
    }
  };

  const {
    callAPI: fetchSharedCases,
    loading: isCasesLoading,
    errored: hasCasesErrored,
  } = useAPI(getCollaborationSharedCases, {
    initialLoading: true,
  });

  React.useEffect(() => {
    loadSharedCases(collaborationUuid as string);
  }, [collaborationUuid]);

  const loadSharedCases = (collaborationUuid: string) => {
    fetchSharedCases(collaborationUuid).then((res) => {
      res && setSharedCases(res.results);
    });
  };

  const openAddCaseModal = async () => {
    await fetchDealCases(currentCollaboration?.deal_details.uuid);
    setIsAddCaseModalOpen(true);
  };

  const closeAddCaseModal = () => {
    setIsAddCaseModalOpen(false);
  };

  const openEditCaseModal = (caseItem: ICollaborationCase) => {
    setSelectedCaseId(caseItem.id);
    setForm({
      name: caseItem.deal_details.name,
      case: caseItem.id,
    });
    setIsEditFormModalOpen(true);
  };

  const closeEditCaseModal = () => {
    setSelectedCaseId(null);
    setIsEditFormModalOpen(false);
  };

  const {
    callAPI: createCase,
    loading: isCreateCaseLoading,
    fieldErrors: createCaseFieldErrors,
    setFieldErrors: setCreateCaseFieldErrors,
  } = useAPI<ICollaborationCase, ICollaborationCaseFormErrors>(
    createCollaborationSharedCase,
  );

  const {
    callAPI: updateCase,
    loading: isUpdateCaseLoading,
    fieldErrors: updateCaseFieldErrors,
    setFieldErrors: setUpdateCaseFieldErrors,
  } = useAPI<ICollaborationCase, ICollaborationCaseFormErrors>(
    updateCollaborationSharedCase,
  );

  const { callAPI: fetchDealCases, response: dealCases } = useAPI(
    (dealUuid: string) => getDealCases(dealUuid),
  );

  const {
    callAPI: sendDealShareEmailCallAPI,
    loading: isSendingDealShareEmail,
    errored: sendDealShareEmailError,
    response: sendDealShareEmailResponse,
  } = useAPI(sendDealShareEmail);

  const addSharedCase = async (form: ICollaborationCaseForm) => {
    const caseItem = await createCase(collaborationUuid, form);
    caseItem && loadSharedCases(collaborationUuid as string);
    return caseItem;
  };

  const editSharedCase = async (form: ICollaborationCaseForm) => {
    const caseItem = await updateCase(collaborationUuid, selectedCaseId, form);
    caseItem && loadSharedCases(collaborationUuid as string);
    return caseItem;
  };

  const { callAPI: deleteDealCallAPI, loading: deleteDealLoading } = useAPI(
    (dealUuid: string) => deleteDeal(dealUuid),
  );

  const handleDeleteCase = async (uuid: string) => {
    const deleted = await deleteDealCallAPI(uuid);

    // remove the case from the table
    deleted && loadSharedCases(collaborationUuid as string);
  };

  const handleOnDelete = (row: ICollaborationCase) => {
    if (row.deal_details.uuid === undefined) {
      return;
    }

    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Shared Case",
        description: "Are you sure you want to delete?",
        onConfirm: () => handleDeleteCase(row.deal_details.uuid),
        loading: deleteDealLoading,
      }),
    );
  };

  const filteredCases = React.useMemo(() => {
    return sharedCases.filter((c) =>
      c.deal_details.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [sharedCases, searchTerm]);

  const visibleCases = React.useMemo(
    () =>
      sortArrayOfObjects(
        filteredCases,
        tableSort?.orderBy,
        tableSort?.order,
      ).slice(
        currentPage * rowsPerPage,
        currentPage * rowsPerPage + rowsPerPage,
      ),
    [filteredCases, tableSort, currentPage, rowsPerPage],
  );

  const handlePageChange = (e: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const gotoDealPage = (dealUuid: string) => {
    if (!organization) {
      const collaborationIdsFromStorage = personalAccountNavBackButtonIds.find(
        (n) => n.collaborationId === collaborationUuid,
      );
      if (collaborationIdsFromStorage) {
        const dealIdsFromStorage = collaborationIdsFromStorage.deals.find(
          (d) => d.uuid === dealUuid,
        );

        const filteredCollabIds = personalAccountNavBackButtonIds.filter(
          (ids) => ids.collaborationId !== collaborationUuid,
        );
        if (!dealIdsFromStorage) {
          setPersonalAccountNavBackButtonIds([
            ...filteredCollabIds,
            {
              collaborationId: collaborationUuid,
              deals: [
                ...collaborationIdsFromStorage.deals,
                { uuid: dealUuid, projectUuids: [] },
              ],
            },
          ]);
        }
      } else {
        setPersonalAccountNavBackButtonIds([
          ...personalAccountNavBackButtonIds,
          {
            collaborationId: collaborationUuid,
            deals: [{ uuid: dealUuid, projectUuids: [] }],
          },
        ]);
      }

      navigate(`/personal/deal/${dealUuid}/general`);
    }
  };

  const dealCaseOptions: ISelectOption[] = React.useMemo(() => {
    if (dealCases) {
      const cases = dealCases.results.map((c) => ({
        label: c.name,
        value: c.uuid,
      }));
      return [
        {
          label: "Base Case",
          value: String(currentCollaboration?.deal_details.uuid),
        },
        ...cases,
      ];
    }
    return [];
  }, [dealCases]);

  const handleSendEmail = async () => {
    await sendDealShareEmailCallAPI(
      collaborationUuid as string,
      null,
      [],
      "CASE",
    );

    dispatch(resetConfirmModalPropsAction());

    if (sendDealShareEmailResponse?.success) {
      toast.success(sendDealShareEmailResponse?.message, {
        toastId: "deal-share-email-success",
      });
    }
  };

  const handleSendEmailConfirmation = async () => {
    dispatch(
      setConfirmModalPropsAction({
        open: true,
        title: "Notify All Counterparties",
        description: `Are you sure you want to notify the counterparty?`,
        onConfirm: () => handleSendEmail(),
      }),
    );
  };
  return (
    <>
      <ViewWrapper loading={isCasesLoading} error={hasCasesErrored}>
        {visibleCases.length === 0 ? (
          <Box className={styles.classes.emptyContainer}>
            <Box className={cn("flex flex-col items-center")}>
              <ConditionalProtect type="externalshare">
                <Button
                  canOpenUpgrade
                  startIcon={<AddIcon />}
                  label="Share Case"
                  onClick={openAddCaseModal}
                  btnType="primary"
                  className={styles.classes.createBtn}
                />
              </ConditionalProtect>
              <Typography
                variant="body1"
                className={styles.classes.createInfo}
                data-pw="empty-project-valuation-summary-message"
              >
                Cases do not exist, click on Share Case to add one.
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box className="flex justify-between items-center mb-4">
              <Box>
                <Searchbar
                  searchString={searchTerm}
                  onSearchStringChange={onSearchTermChange}
                  handleClearSearchString={handleClearSearchTerm}
                />
              </Box>
              <ConditionalProtect type="externalshare">
                <Button
                  startIcon={<AddIcon />}
                  btnType="primary"
                  label="Share Case"
                  onClick={openAddCaseModal}
                />
              </ConditionalProtect>
            </Box>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead classes={{ root: styles.classes.tableHeader }}>
                    <TableRow>
                      {columns.map((column, idx) => (
                        <TableCell
                          key={idx}
                          align={column.align as "left" | "right"}
                          style={{ minWidth: column.minWidth }}
                        >
                          <TableSortLabel
                            active={tableSort.orderBy === column.id}
                            direction={
                              tableSort.orderBy === column.id
                                ? tableSort.order
                                : "asc"
                            }
                            onClick={() => handleSort(column.id)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {visibleCases.map((row, idx) => (
                      <TableRow
                        hover
                        key={idx}
                        tabIndex={-1}
                        data-pw={`row-${idx + 1}`}
                        onClick={() => gotoDealPage(row.deal_details.uuid)}
                      >
                        <TableCell>
                          {trimString(row.deal_details.name, 30)}
                        </TableCell>
                        <TableCell>
                          {DEAL_STRUCTURE_TYPE[row.deal_details.structure]}
                        </TableCell>
                        <TableCell>
                          {
                            DEAL_TAX_CREDIT_STRUCTURE_TYPE[
                              row.deal_details.tax_credit_structure
                            ]
                          }
                        </TableCell>
                        <TableCell>
                          {
                            DEAL_TAX_CREDIT_TYPE[
                              row.deal_details.tax_credit_type
                            ]
                          }
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={
                              CREATION_DEAL_STATUS[
                                row.deal_details
                                  .status as keyof typeof CREATION_DEAL_STATUS
                              ]
                            }
                            color={
                              getStatusChipFillColor(row.deal_details.status)
                                .color
                            }
                            filledBackgroundColor={
                              getStatusChipFillColor(row.deal_details.status)
                                .backgroundColor
                            }
                            variant="filled"
                          />
                        </TableCell>
                        <TableCell align="right">
                          <ConditionalProtect type="externalshare">
                            <PopoverMenu
                              uniqueId={row.id}
                              canOpenUpgrade
                              items={[
                                {
                                  label: "Edit",
                                  onClick: () => openEditCaseModal(row),
                                },
                                {
                                  label: "Delete",
                                  onClick: () => handleOnDelete(row),
                                },
                                {
                                  label: "Notify All Counterparties",
                                  onClick: handleSendEmailConfirmation,
                                },
                              ]}
                            />
                          </ConditionalProtect>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                className={cn("bg-table-gray")}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredCases.length}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Paper>
          </Box>
        )}
      </ViewWrapper>

      <CollaborationCaseFormModal
        open={isAddCaseModalOpen}
        headerLabel="Share Case"
        loading={isCreateCaseLoading}
        form={form}
        setForm={setForm}
        formErrors={createCaseFieldErrors}
        setFormErrors={setCreateCaseFieldErrors}
        onClose={closeAddCaseModal}
        onConfirm={addSharedCase}
        caseOptions={dealCaseOptions}
        baseCaseId={currentCollaboration?.deal_details.uuid}
      />

      <CollaborationCaseFormModal
        open={isEditFormModalOpen}
        headerLabel="Edit Shared Case"
        loading={isUpdateCaseLoading}
        form={form}
        setForm={setForm}
        formErrors={updateCaseFieldErrors}
        setFormErrors={setUpdateCaseFieldErrors}
        onClose={closeEditCaseModal}
        onConfirm={editSharedCase}
        caseOptions={dealCaseOptions}
        baseCaseId={currentCollaboration?.deal_details.uuid}
        hideFields={["case"]}
      />
    </>
  );
}
