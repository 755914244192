import React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import useStyles from "./styles";
import Logs from "../../../../../components/logs";
import Button from "../../../../../components/button";
import LogsWrapper from "../../../../../components/logs-wrapper";
import ViewWrapper from "../../../../../components/view-wrapper";
import LogsButton from "../../../../../components/logs-button";
import DetailsCard from "../../../../../components/details-card";
import ReportTableV2 from "../../../../../components/report-table-v2";
import ProjectCreditSupportFormModal from "../../../../../components/project-credit-support-form-modal";
import ConditionalProtect from "../../../../../components/conditional-protect";
import { setDeleteModalPropsAction } from "../../../../../utils/redux/slices";
import { useAPI, useDrawer, useLogs } from "../../../../../utils/hooks";
import {
  cn,
  numberToUSD,
  numberWithDecimalsAndCommas,
  trimString,
} from "../../../../../utils/helpers";
import {
  IUpdateProjectCreditSupportForm,
  IProjectCreditSupport,
  IUpdateProjectCreditSupportFormErrors,
  ILogsConfiguration,
  IReportTable,
} from "../../../../../interfaces";
import {
  PROJECT_OBLICATION_TYPE,
  PROJECT_CREDIT_SUPPORT_COST_METHOD,
  MILESTONE_DATE_TYPES,
  DATE_SELECTION_TYPES,
  YEAR_FRAC_CONVENTION_TYPE,
} from "../../../../../constants";

interface IProps {
  getDetailsCreditSupport: (
    projectUuid: string,
    creditSupportId: number,
  ) => Promise<IProjectCreditSupport>;
  editProjectCreditSupport: (
    projectUuid: string,
    creditSupportId: number,
    form: IUpdateProjectCreditSupportForm,
  ) => Promise<IProjectCreditSupport>;
  deleteProjectCreditSupport: (
    projectUuid: string,
    creditSupportId: number,
  ) => Promise<boolean>;
  getCreditSupportDetailSchedule: (
    projectUuid: string,
    projectCreditSupportId: number,
  ) => Promise<IReportTable>;
}

export default function CreditSupportDetailsView({
  getDetailsCreditSupport,
  editProjectCreditSupport,
  deleteProjectCreditSupport,
  getCreditSupportDetailSchedule,
}: IProps): JSX.Element {
  const styles = useStyles();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { projectUuid, creditSupportId } = useParams();

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [credit_support, setCreditSupport] =
    React.useState<IProjectCreditSupport>();
  const [projectCostDetailSchedule, setProjectCostDetailSchedule] =
    React.useState<IReportTable>();
  const [updateModalOpen, setUpdateModalOpen] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<IUpdateProjectCreditSupportForm>({
    obligation_type: "",
    name: "",
    credit_support_method: "",
    input_type: "",
    credit_support_amount: "",
    start_date_type: "",
    credit_support_start_date: "",
    credit_support_start_point: "",
    end_date_type: "",
    credit_support_end_date: "",
    credit_support_end_point: "",
    letter_of_credit_fee: "",
    yearfrac_convention: "",
  });

  React.useEffect(() => {
    getDetailsCreditSupportCallAPI(projectUuid, creditSupportId).then(
      (response) => {
        setCreditSupport(response);
      },
    );
    getProjectCostDetailScheduleCallAPI(
      projectUuid,
      Number(creditSupportId),
    ).then((res) => {
      setProjectCostDetailSchedule(res);
    });
  }, [projectUuid, creditSupportId]);

  const {
    callAPI: getDetailsCreditSupportCallAPI,
    loading: loadingDetailsCreditSupport,
    errored: getDetailsCreditSupportFailed,
  } = useAPI(
    (projectId, creditSupportId) =>
      getDetailsCreditSupport(projectId, creditSupportId),
    { initialLoading: true },
  );

  const {
    callAPI: getProjectCostDetailScheduleCallAPI,
    loading: loadingProjectCostDetailSchedule,
    errored: getProjectCostDetailScheduleFailed,
  } = useAPI((projectUuid, projectCreditSupportId) =>
    getCreditSupportDetailSchedule(projectUuid, projectCreditSupportId),
  );

  const {
    callAPI: editProjectCreditSupportCallAPI,
    loading: loadingEditProjectCreditSupport,
    fieldErrors: editProjectCreditSupportFieldErrors,
    setFieldErrors: setEditProjectCreditSupportFieldErrors,
  } = useAPI<IProjectCreditSupport, IUpdateProjectCreditSupportFormErrors>(
    (projectUuid, creditSupportId, form) =>
      editProjectCreditSupport(projectUuid, creditSupportId, form),
  );

  const { callAPI: deleteProjectCreditSupportCallAPI } = useAPI(
    (projectUuid, creditSupportId) =>
      deleteProjectCreditSupport(projectUuid, creditSupportId),
    { setConfirmModalLoading: true },
  );

  const handleCloseUpdateCreditSupportModal = () => {
    setUpdateModalOpen(false);
    setForm({
      obligation_type: "",
      name: "",
      credit_support_method: "",
      input_type: "",
      credit_support_amount: "",
      start_date_type: "",
      credit_support_start_date: "",
      credit_support_start_point: "",
      end_date_type: "",
      credit_support_end_date: "",
      credit_support_end_point: "",
      letter_of_credit_fee: "",
      yearfrac_convention: "",
    });
  };

  const handleCreditSupportUpdate = async (
    form: IUpdateProjectCreditSupportForm,
  ) => {
    const credit_support = await editProjectCreditSupportCallAPI(
      projectUuid,
      creditSupportId,
      form,
    );
    if (credit_support) {
      setCreditSupport(credit_support);
      handleCloseUpdateCreditSupportModal();
    }
    return credit_support;
  };

  const handleOpenUpdateCreditSupportModal = () => {
    if (!credit_support) {
      return;
    }
    const {
      obligation_type,
      name,
      credit_support_method,
      input_type,
      credit_support_amount,
      start_date_type,
      credit_support_start_date,
      credit_support_start_point,
      end_date_type,
      credit_support_end_date,
      credit_support_end_point,
      letter_of_credit_fee,
      yearfrac_convention,
    } = credit_support;
    setForm({
      obligation_type,
      name,
      credit_support_method,
      input_type,
      credit_support_amount,
      start_date_type,
      credit_support_start_date,
      credit_support_start_point,
      end_date_type,
      credit_support_end_date,
      credit_support_end_point,
      letter_of_credit_fee,
      yearfrac_convention,
    });
    setUpdateModalOpen(true);
  };

  const handleDeleteCreditSupport = async () => {
    const creditSupportDeleted = await deleteProjectCreditSupportCallAPI(
      projectUuid,
      creditSupportId,
    );

    if (creditSupportDeleted) {
      navigate(`/project/${projectUuid}/cost-credit-support`);
    }
  };

  const handleOnDeleteClick = async () => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Credit Support",
        description: "Are you sure you want to delete this credit support?",
        onConfirm: handleDeleteCreditSupport,
      }),
    );
  };

  const projectCreditSupportLogsConfiguration: ILogsConfiguration = {
    id: Number(creditSupportId),
    type: "projectcreditsupport",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      projectCreditSupportLogsConfiguration.type,
      projectCreditSupportLogsConfiguration.id,
    );
  };

  const getCreditSupportAmount = (): string => {
    switch (credit_support?.input_type) {
      case "DL":
        return numberToUSD.format(credit_support?.credit_support_amount);
      case "DLPAD":
        return (
          numberWithDecimalsAndCommas(credit_support?.credit_support_amount) +
          " $/kWac"
        );
      default:
        return (
          numberWithDecimalsAndCommas(
            credit_support?.credit_support_amount ?? 0,
          ) ?? "N/A"
        );
    }
  };

  return (
    <>
      <ViewWrapper
        loading={loadingDetailsCreditSupport}
        error={getDetailsCreditSupportFailed}
      >
        <Box className={cn("flex justify-between gap-4 mb-4")}>
          <IconButton
            classes={{ root: styles.classes.iconButton }}
            onClick={() => window.history.back()}
          >
            <ArrowBackIcon />
          </IconButton>
          <Box className={cn("flex gap-4")}>
            <LogsButton onClick={handleOnOpenLogs} />

            <ConditionalProtect type="project">
              <Button
                canOpenUpgrade
                startIcon={<EditIcon />}
                label="Edit"
                btnType="primary"
                onClick={handleOpenUpdateCreditSupportModal}
              />
              <Button
                canOpenUpgrade
                label="Delete"
                btnType="danger"
                onClick={handleOnDeleteClick}
              />
            </ConditionalProtect>
          </Box>
        </Box>

        <Box className={cn("grid md:grid-cols-2 gap-4 mb-4")}>
          {credit_support && (
            <DetailsCard
              heading="Credit Support Details"
              dataPw="expense-details"
              autoHeight
              sections={[
                {
                  fields: [
                    {
                      label: "Credit Support Type",
                      value: {
                        text:
                          PROJECT_OBLICATION_TYPE[
                            credit_support?.obligation_type
                          ] || "",
                      },
                    },
                    {
                      label: "Name",
                      value: {
                        text: trimString(credit_support.name, 40),
                      },
                    },
                    {
                      label: "Amount",
                      value: {
                        text: getCreditSupportAmount(),
                      },
                    },
                    {
                      label: "Start Date Type",
                      value: {
                        text: DATE_SELECTION_TYPES[
                          credit_support?.start_date_type
                        ],
                      },
                    },
                    ...(credit_support.start_date_type !== "MD"
                      ? [
                          {
                            label: "Start Date",
                            value: {
                              text: credit_support?.credit_support_start_date,
                            },
                          },
                        ]
                      : []),
                    ...(credit_support.start_date_type !== "SPD"
                      ? [
                          {
                            label: "Start Point",
                            value: {
                              text: MILESTONE_DATE_TYPES[
                                credit_support?.credit_support_start_point
                              ],
                            },
                          },
                        ]
                      : []),
                    {
                      label: "End Date Type",
                      value: {
                        text: DATE_SELECTION_TYPES[
                          credit_support?.end_date_type
                        ],
                      },
                    },
                    ...(credit_support.end_date_type !== "MD"
                      ? [
                          {
                            label: "End Date",
                            value: {
                              text: credit_support?.credit_support_end_date,
                            },
                          },
                        ]
                      : []),
                    ...(credit_support.end_date_type !== "SPD"
                      ? [
                          {
                            label: "End Point",
                            value: {
                              text: MILESTONE_DATE_TYPES[
                                credit_support?.credit_support_end_point
                              ],
                            },
                          },
                        ]
                      : []),
                    {
                      label: "Security Type",
                      value: {
                        text: PROJECT_CREDIT_SUPPORT_COST_METHOD[
                          credit_support?.credit_support_method
                        ],
                      },
                    },
                    ...(typeof credit_support.letter_of_credit_fee === "number"
                      ? [
                          {
                            label: "Letter Of Credit Fee",
                            value: {
                              text: credit_support.letter_of_credit_fee + "%",
                            },
                          },
                        ]
                      : []),
                    ...(credit_support.credit_support_method === "LC"
                      ? [
                          {
                            label: "Yearfrac Convention",
                            value: {
                              text: YEAR_FRAC_CONVENTION_TYPE[
                                credit_support?.yearfrac_convention
                              ],
                            },
                          },
                        ]
                      : []),
                  ],
                },
              ]}
            />
          )}
        </Box>
      </ViewWrapper>

      <ViewWrapper
        loading={loadingProjectCostDetailSchedule}
        error={getProjectCostDetailScheduleFailed}
      >
        {projectCostDetailSchedule && (
          <ReportTableV2
            data={projectCostDetailSchedule}
            groupExpandedDepth={-1}
          />
        )}
      </ViewWrapper>

      <ProjectCreditSupportFormModal
        open={updateModalOpen}
        loading={loadingEditProjectCreditSupport}
        headerLabel="Update Credit Support"
        formErrors={editProjectCreditSupportFieldErrors}
        setFormErrors={setEditProjectCreditSupportFieldErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseUpdateCreditSupportModal}
        onConfirm={handleCreditSupportUpdate}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={projectCreditSupportLogsConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={projectCreditSupportLogsConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
