import React from "react";
import Box from "@mui/material/Box";

import Modal from "../modal";
import useStyles from "./styles";
import TextInput from "../text-input";
import { UPDATE_CASE_FORM_DEFAULT_STATE } from "../../constants";
import {
  IDealCase,
  SetStateAction,
  IUpdateDealCaseForm,
  IUpdateDealCaseFormErrors,
} from "../../interfaces";

interface IProps {
  headerLabel: string;
  open: boolean;
  loading: boolean;
  form: IUpdateDealCaseForm;
  setForm: SetStateAction<IUpdateDealCaseForm>;
  formErrors?: IUpdateDealCaseFormErrors;
  setFormErrors: SetStateAction<IUpdateDealCaseFormErrors | undefined>;
  onClose: () => void;
  onConfirm: (form: IUpdateDealCaseForm) => Promise<IDealCase | undefined>;
}

export default function UpdateCaseFormModal({
  headerLabel,
  open,
  loading,
  form,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const styles = useStyles();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleOnEdit = async () => {
    const dealCase = await onConfirm(form);
    dealCase && handleOnClose();
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleResetForm = () => {
    setForm(UPDATE_CASE_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      heading={headerLabel}
      open={open}
      form={form}
      loading={loading}
      onClose={handleOnClose}
      onConfirm={handleOnEdit}
      resetForm={handleResetForm}
    >
      <Box>
        <TextInput
          required
          label="Case Name"
          name="name"
          value={form.name}
          onFocus={clearErrorOnFocus}
          onChange={handleChange}
          error={Boolean(formErrors?.name)}
          helperText={formErrors?.name}
          disabled={loading}
          autoFocus
        />
        <TextInput
          classes={{ root: styles.classes.input }}
          multiline
          rows={4}
          label="Description"
          name="description"
          value={form.description}
          onFocus={clearErrorOnFocus}
          onChange={handleChange}
          error={Boolean(formErrors?.description)}
          helperText={formErrors?.description}
          disabled={loading}
        />
      </Box>
    </Modal>
  );
}
