import React from "react";
import Box from "@mui/material/Box";

import Modal from "../modal";
import TextInput from "../text-input";
import {
  SetStateAction,
  IAddCurveGroupFormErrors,
  IAddCurveGroupForm,
  ICurveGroup,
  IUpdateCurveGroupFormErrors,
  IUpdateCurveGroupForm,
} from "../../interfaces";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  formErrors?: IAddCurveGroupFormErrors | IUpdateCurveGroupFormErrors;
  setFormErrors: SetStateAction<
    IAddCurveGroupFormErrors | IUpdateCurveGroupFormErrors | undefined
  >;
  form: IAddCurveGroupForm | IUpdateCurveGroupForm;
  setForm: SetStateAction<IAddCurveGroupForm | IUpdateCurveGroupForm>;
  onClose: () => void;
  onConfirm: (
    form: IAddCurveGroupForm | IUpdateCurveGroupForm,
  ) => Promise<ICurveGroup | undefined>;
}

export default function CurveGroupFormModal({
  open,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    const curveGroup = await onConfirm(form);
    curveGroup && handleOnClose();
  };

  const handleResetForm = () => {
    setForm({
      name: "",
      description: "",
    });
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      open={open}
      form={form}
      heading={headerLabel}
      loading={loading}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <Box>
        <TextInput
          required
          label="Name"
          name="name"
          value={form.name}
          onFocus={clearErrorOnFocus}
          onChange={handleTextChange}
          error={Boolean(formErrors?.name)}
          helperText={formErrors?.name}
          disabled={loading}
          autoFocus
        />
        <TextInput
          label="Description"
          name="description"
          value={form.description}
          onFocus={clearErrorOnFocus}
          onChange={handleTextChange}
          error={Boolean(formErrors?.description)}
          helperText={formErrors?.description}
          disabled={loading}
        />
      </Box>
    </Modal>
  );
}
