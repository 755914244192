import React from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import TablePagination from "@mui/material/TablePagination";
import { Protect } from "@clerk/clerk-react";
import { format, startOfMonth, startOfYear } from "date-fns";
import { useDispatch } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import useStyles from "./styles";
import Button from "../../../components/button";
import IconButton from "../../../components/icon-button";
import ViewWrapper from "../../../components/view-wrapper";
import CurveFormModal from "../../../components/curve-form-modal";
import CurveGroupFormModal from "../../../components/curve-group-form-modal";
import CurvesDetailPage from "../../../pages/configuration/data/curves-detail";
import StickyTableCell from "../../../components/sticky-table-cell";
import MultiAutocompleteField from "../../../components/multi-autocomplete-field";
import { useAPI, useAppSelector } from "../../../utils/hooks";
import { cn, sortArrayOfObjects, trimString } from "../../../utils/helpers";
import {
  setCurrentOrgCurveGroupsAction,
  setCurrentOrgCurvesAction,
  setDeleteModalPropsAction,
} from "../../../utils/redux/slices";
import {
  CURVE_TYPE,
  USER_PERMISSIONS,
  PERIODICITY,
  CURVE_TYPE_OPTIONS,
} from "../../../constants";
import {
  IAddCurveGroupForm,
  IAddCurveGroupFormErrors,
  ICurveGroup,
  IDateScheduleParams,
  IDateScheduleParamsErrors,
  IGetCurvesParams,
  IOrganizationCurve,
  ISelectOption,
  ITableSort,
  ICurveForm,
  ICurveFormErrors,
  IUpdateCurveGroupForm,
  IUpdateCurveGroupFormErrors,
  ServerPaginatedResponse,
  IUserData,
} from "../../../interfaces";

interface IProps {
  getCurveGroups: () => Promise<ServerPaginatedResponse<ICurveGroup[]>>;
  updateCurveGroup: (
    cgUuid: string,
    form: IUpdateCurveGroupForm,
  ) => Promise<ICurveGroup>;
  deleteCurveGroup: (cgUuid: string) => Promise<boolean>;
  getCurves: (
    params: IGetCurvesParams,
  ) => Promise<ServerPaginatedResponse<IOrganizationCurve[]>>;
  createCurveGroup: (form: IAddCurveGroupForm) => Promise<ICurveGroup>;
  addCurve: (form: ICurveForm) => Promise<IOrganizationCurve>;
  getDateSchedule: (
    form: IDateScheduleParams,
  ) => Promise<{ schedule_dates: string[] }>;
  getUser: () => Promise<IUserData>;
}

const CURVES_TABLE_COLUMNS = [
  { id: "name", label: "Curve Name", minWidth: 200, align: "left" },
  {
    id: "curve_type",
    label: "Type",
    minWidth: 80,
    align: "left",
  },
  {
    id: "periodicity",
    label: "Periodicity",
    minWidth: 80,
    align: "left",
  },
];

export default function CurvesView({
  getCurveGroups,
  getCurves,
  createCurveGroup,
  addCurve,
  getDateSchedule,
  updateCurveGroup,
  deleteCurveGroup,
  getUser,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { curveGroupId, curveUuid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const { ctrlPressed } = useAppSelector((s) => s.common);
  const { currentOrgCurveGroups, currentOrgCurves } = useAppSelector(
    (s) => s.org,
  );

  const [filterParams] = useSearchParams();

  const [editGroupModalOpen, setEditGroupModalOpen] =
    React.useState<boolean>(false);
  const [addGroupModalOpen, setAddGroupModalOpen] =
    React.useState<boolean>(false);
  const [addCurveModalOpen, setAddCurveModalOpen] =
    React.useState<boolean>(false);
  const [editCurveModalOpen, setEditCurveModalOpen] =
    React.useState<boolean>(false);
  const [addCurveGroupForm, setAddCurveGroupForm] =
    React.useState<IAddCurveGroupForm>({
      name: "",
      description: "",
    });
  const [editCurveGroupForm, setEditCurveGroupForm] =
    React.useState<IUpdateCurveGroupForm>({
      name: "",
      description: "",
    });
  const [curveForm, setCurveForm] = React.useState<ICurveForm>({
    curve_group: "",
    curve_type: "",
    name: "",
    periodicity: "",
    price_term: "",
    start_date: "",
    term_years: "",
    curve_data: null,
  });
  const [dateSchedule, setDateSchedule] = React.useState<string[]>([]);
  const [searchString, setSearchString] = React.useState("");
  const [selectedCurveTypes, setSelectedCurveTypes] = React.useState<
    (keyof typeof CURVE_TYPE)[]
  >(() => {
    const types = searchParams.get("types");
    return types ? (types.split(",") as (keyof typeof CURVE_TYPE)[]) : [];
  });
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });
  const [selectedGroupToEdit, setSelectedGroupToEdit] = React.useState<
    string | null
  >(null);
  const [selectedCurveToEdit, setSelectedCurveToEdit] =
    React.useState<IOrganizationCurve | null>(null);

  const sortRows = (orderBy: string) => {
    if (orderBy === sortTable.orderBy) {
      setSortTable({
        orderBy,
        order: sortTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const onSearchStringChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setSearchString(e.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredRows = React.useMemo(() => {
    return currentOrgCurves.filter((d) =>
      d.name.toLowerCase().includes(searchString.toLowerCase()),
    );
  }, [currentOrgCurves, searchString]);

  const visibleRows = React.useMemo(
    () =>
      sortArrayOfObjects(
        filteredRows,
        sortTable?.orderBy,
        sortTable?.order,
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [filteredRows, sortTable, page, rowsPerPage],
  );

  const { callAPI: getCurveGroupsCallAPI, loading: groupsLoading } = useAPI(
    () => getCurveGroups(),
  );
  const { callAPI: getDateScheduleCallAPI, loading: loadingDateSchedule } =
    useAPI<{ schedule_dates: string[] }, IDateScheduleParamsErrors>(
      (form: IDateScheduleParams) => getDateSchedule(form),
    );
  const {
    callAPI: getCurvesCallAPI,
    loading: curvesLoading,
    errored: getCurvesErrored,
  } = useAPI((params: IGetCurvesParams) => getCurves(params));
  const { callAPI: getUserCallAPI, response: userData } = useAPI(getUser, {
    initialLoading: true,
  });
  const {
    callAPI: addCurveGroupCallAPI,
    loading: addCurveGroupLoading,
    fieldErrors: addCurveGroupFormErrors,
    setFieldErrors: SetAddCurveGroupFormErrors,
  } = useAPI<ICurveGroup, IAddCurveGroupFormErrors>(
    (form: IAddCurveGroupForm) => createCurveGroup(form),
  );
  const {
    callAPI: editCurveGroupCallAPI,
    loading: editCurveGroupLoading,
    fieldErrors: editCurveGroupFormErrors,
    setFieldErrors: setEditCurveGroupFormErrors,
  } = useAPI<ICurveGroup, IUpdateCurveGroupFormErrors>(
    (cgUuid: string, form: IUpdateCurveGroupForm) =>
      updateCurveGroup(cgUuid, form),
  );

  const {
    callAPI: addCurveCallAPI,
    loading: addCurveLoading,
    fieldErrors: addCurveFormErrors,
    setFieldErrors: SetAddCurveFormErrors,
  } = useAPI<IOrganizationCurve, ICurveFormErrors>((form: ICurveForm) =>
    addCurve(form),
  );

  const { callAPI: deleteCurveGroupCallAPI } = useAPI(
    (cgUuid: string) => deleteCurveGroup(cgUuid),
    { setConfirmModalLoading: true },
  );

  const currentGroup = React.useMemo(() => {
    return filterParams.get("curve_group");
  }, [filterParams]);

  const showCurveDetailsPage = React.useMemo(() => {
    return curveUuid;
  }, [curveUuid]);

  const isShowingAllGroups = React.useMemo(() => {
    const hasOnlyTypeFilter =
      location.search.startsWith("?types=") &&
      !location.search.includes("curve_group");
    const hasNoFilters = !location.search;
    const isListView = !showCurveDetailsPage;

    return (hasNoFilters || hasOnlyTypeFilter) && isListView;
  }, [location, showCurveDetailsPage]);

  const getCurvesList = (params: IGetCurvesParams) => {
    getCurvesCallAPI(params).then((res) => {
      res && dispatch(setCurrentOrgCurvesAction(res.results));
    });
  };

  React.useEffect(() => {
    getCurveGroupsCallAPI().then((res) => {
      res && dispatch(setCurrentOrgCurveGroupsAction(res.results));
    });
    getUserCallAPI();
  }, []);

  // call curves api with curve_group whenever params change or pages switch between curve list and curve detail page
  React.useEffect(() => {
    if (currentGroup || isShowingAllGroups || selectedCurveTypes) {
      getCurvesList({
        curve_group: currentGroup || undefined,
        curve_type: selectedCurveTypes,
      });
    }
  }, [currentGroup, isShowingAllGroups, selectedCurveTypes]);

  // get date schedules for scheduler input when the following field changes in add curve modal.
  React.useEffect(() => {
    if (addCurveModalOpen) {
      const { periodicity, start_date, term_years } = curveForm;
      if (periodicity && start_date && term_years?.toString()) {
        let updatedStartDate = format(
          startOfMonth(new Date(start_date)),
          "MM/dd/yyyy",
        );
        if (periodicity === "AN") {
          updatedStartDate = format(
            startOfYear(new Date(start_date)),
            "MM/dd/yyyy",
          );
        }
        getDateScheduleCallAPI({
          periodicity,
          start_date: updatedStartDate,
          term_years,
        })
          .then((res) => res && setDateSchedule(res.schedule_dates))
          .catch(() => null);
      }
    }
  }, [curveForm.periodicity, curveForm?.start_date, curveForm.term_years]);

  // get date schedules for scheduler input when the following field changes in edit curve modal.
  React.useEffect(() => {
    if (editCurveModalOpen) {
      const { periodicity, start_date, term_years } = curveForm;
      let updatedStartDate = format(
        startOfMonth(new Date(start_date)),
        "MM/dd/yyyy",
      );
      if (periodicity === "AN") {
        updatedStartDate = format(
          startOfYear(new Date(start_date)),
          "MM/dd/yyyy",
        );
      }
      if (periodicity && start_date && term_years?.toString()) {
        getDateScheduleCallAPI({
          periodicity,
          start_date: updatedStartDate,
          term_years,
        })
          .then((res) => res && setDateSchedule(res.schedule_dates))
          .catch(() => null);
      }
    }
  }, [curveForm.periodicity, curveForm?.start_date, curveForm.term_years]);

  const handleOpenAddGroupModal = () => {
    setAddGroupModalOpen(true);
  };

  const handleCloseAddGroupModal = () => {
    setAddGroupModalOpen(false);
  };

  const handleOpenEditGroupModal = (
    event: React.MouseEvent<HTMLButtonElement>,
    uuid: string,
  ) => {
    event.stopPropagation();
    const group = currentOrgCurveGroups.find((c) => c.uuid === uuid);
    if (group) {
      const { uuid, name, description } = group;
      setEditCurveGroupForm({ name, description });
      setSelectedGroupToEdit(uuid);
      setEditGroupModalOpen(true);
    }
  };

  const handleCloseEditGroupModal = () => {
    setSelectedGroupToEdit(null);
    setEditGroupModalOpen(false);
  };

  const handleCloseEditCurveModal = () => {
    setEditCurveModalOpen(false);
    setSelectedCurveToEdit(null);
    // clearing date schedule when closing modal
    setDateSchedule([]);
  };

  const handleGetCurveGroups = async () => {
    const groups = await getCurveGroups().catch(() => null);
    groups && dispatch(setCurrentOrgCurveGroupsAction(groups.results));
  };

  const handleOpenAddCurveModal = async () => {
    // get all the curve group options
    await handleGetCurveGroups();
    // if inside a curve group, adding a new curve, in the modal default to that curve group you're in already
    if (currentGroup) {
      setCurveForm((prev) => ({
        ...prev,
        curve_group: currentGroup,
      }));
    }
    setAddCurveModalOpen(true);
  };

  const handleCloseAddCurveModal = () => {
    setAddCurveModalOpen(false);
    // clearing date schedule when closing modal
    setDateSchedule([]);
  };

  const onAddGroup = async (form: IAddCurveGroupForm) => {
    const group = await addCurveGroupCallAPI(form);
    if (group) {
      dispatch(
        setCurrentOrgCurveGroupsAction([group, ...currentOrgCurveGroups]),
      );

      navigate(`/configuration/data/curves?curve_group=${group?.uuid}`);
      // if curve modal is open set this as the selected group
      if (addCurveModalOpen) {
        setCurveForm((prev) => ({ ...prev, curve_group: group.uuid }));
      }
    }
    return group;
  };

  const onEditGroup = async (form: IUpdateCurveGroupForm) => {
    const group = await editCurveGroupCallAPI(selectedGroupToEdit, form);
    if (group) {
      dispatch(
        setCurrentOrgCurveGroupsAction(
          currentOrgCurveGroups.map((g) => {
            return g.uuid === group.uuid ? group : g;
          }),
        ),
      );
    }
    return group;
  };

  const onAddCurve = async (form: ICurveForm) => {
    // Update the start_date to the 1st of the month
    const updatedForm = { ...form };
    if (updatedForm.start_date) {
      updatedForm.start_date = format(
        startOfMonth(new Date(updatedForm.start_date)),
        "MM/dd/yyyy",
      );
    }
    const curve = await addCurveCallAPI(updatedForm);
    if (curve) {
      dispatch(setCurrentOrgCurvesAction([curve, ...currentOrgCurves]));
      navigateToCurveDetailPage(curve.curve_group, curve.uuid);
    }
    return curve;
  };

  const handleDeleteGroup = async (uuid: string) => {
    const deletedGroup = await deleteCurveGroupCallAPI(uuid);
    if (deletedGroup) {
      dispatch(
        setCurrentOrgCurveGroupsAction(
          currentOrgCurveGroups.filter((c) => c.uuid !== uuid),
        ),
      );

      // remove the curves under that group
      dispatch(
        setCurrentOrgCurvesAction(
          currentOrgCurves.filter((c) => c.curve_group !== uuid),
        ),
      );

      // if the deleted group is selected then navigate to curves list
      if (isGroupSelected(uuid)) {
        navigate("/configuration/data/curves");
      }
    }
  };

  const handleOpenDeleteGroupModal = (
    event: React.MouseEvent<HTMLButtonElement>,
    uuid: string | undefined,
  ) => {
    event.stopPropagation();
    if (uuid === undefined) {
      return;
    }
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Group",
        description: "Are you sure you want to delete?",
        onConfirm: () => handleDeleteGroup(uuid),
      }),
    );
  };

  const navigateToCurveDetailPage = (curveGroup: string, curveUuid: string) => {
    if (ctrlPressed) {
      window.open(`/configuration/data/curves/${curveGroup}/${curveUuid}`);
    } else {
      navigate(`/configuration/data/curves/${curveGroup}/${curveUuid}`);
    }
  };

  const isGroupSelected = React.useCallback(
    (uuid: string) => {
      return (currentGroup || curveGroupId) === String(uuid); // set group active when that group's id is present in search params or we are in a curve which belongs to this group
    },
    [currentGroup, curveGroupId],
  );

  // preparing curve group options
  const curveGroupsOptions: ISelectOption[] = React.useMemo(() => {
    return currentOrgCurveGroups?.map((g) => {
      return {
        label: g.name,
        value: g.uuid,
      };
    });
  }, [currentOrgCurveGroups]);

  const handleMultiAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    values: ISelectOption[],
    name: string,
  ) => {
    const selectedValues = values.map((v) => v.value);
    setSelectedCurveTypes(selectedValues as (keyof typeof CURVE_TYPE)[]);

    const params = new URLSearchParams(searchParams);
    if (selectedValues.length) {
      params.set("types", selectedValues.join(","));
    } else {
      params.delete("types");
    }
    setSearchParams(params);
  };

  const gotoCurveGroup = (uuid?: string) => {
    const params = new URLSearchParams(searchParams);
    if (uuid) {
      params.set("curve_group", uuid);
    } else {
      params.delete("curve_group");
    }
    navigate(`/configuration/data/curves?${params.toString()}`);
    setPage(0); // reset page to 0 when changing group
  };

  return (
    <>
      <Box className={cn("flex")}>
        <Box className={cn("w-[20%]")}>
          <Box className={cn("min-h-full")}>
            <Box
              className={cn(
                "flex flex-col gap-4 my-4 !h-[calc(100vh_-_400px)] overflow-y-auto min-h-full",
              )}
            >
              {/* skeleton loading for curve groups */}
              {groupsLoading ? (
                <Box>
                  <Skeleton height={30} width="90%" />
                  <Skeleton height={30} width="90%" />
                  <Skeleton height={30} width="90%" />
                </Box>
              ) : (
                <Box>
                  {/* for all groups */}
                  <Box
                    className={`${styles.classes.group} ${
                      isShowingAllGroups && styles.classes.activeGroup
                    }`}
                    onClick={() => gotoCurveGroup()}
                  >
                    All Groups
                  </Box>
                  {currentOrgCurveGroups?.map((group, idx) => {
                    return (
                      <Box
                        key={idx}
                        className={`${styles.classes.group} ${
                          isGroupSelected(group.uuid) &&
                          styles.classes.activeGroup
                        }`}
                        onClick={() => gotoCurveGroup(group.uuid)}
                      >
                        <Typography>{trimString(group?.name, 15)}</Typography>
                        {/* condition below to see if created_by is same as this user or has EDITOR permission */}
                        <Protect
                          condition={(has) => {
                            return (
                              has({
                                permission: USER_PERMISSIONS.ORG_CURVE_EDITOR,
                              }) || userData?.id === group?.created_by
                            );
                          }}
                        >
                          <Box>
                            <IconButton
                              canOpenUpgrade
                              onClick={(event) =>
                                handleOpenEditGroupModal(event, group?.uuid)
                              }
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              canOpenUpgrade
                              onClick={(event) =>
                                handleOpenDeleteGroupModal(event, group?.uuid)
                              }
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </Protect>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
            <Protect permission={USER_PERMISSIONS.ORG_CURVE_CREATOR}>
              <Box className={cn("flex justify-center")}>
                <Button
                  label="Add Group"
                  btnType="primary"
                  canOpenUpgrade
                  startIcon={<AddIcon />}
                  onClick={handleOpenAddGroupModal}
                />
              </Box>
            </Protect>
          </Box>
        </Box>
        <Box className={cn("w-[80%] p-4")}>
          {showCurveDetailsPage ? (
            <CurvesDetailPage />
          ) : (
            <Box>
              <Box className={cn("flex justify-between items-center mb-4")}>
                <div className="flex gap-4">
                  <TextField
                    label="Search"
                    size="small"
                    onChange={onSearchStringChange}
                    value={searchString}
                  />
                  <MultiAutocompleteField
                    label="Type"
                    name="selectedCurveTypes"
                    size="small"
                    options={CURVE_TYPE_OPTIONS}
                    value={selectedCurveTypes}
                    onChange={handleMultiAutoCompleteChange}
                    className={cn("!min-w-[216px]")}
                  />
                </div>
                <Protect permission={USER_PERMISSIONS.ORG_CURVE_CREATOR}>
                  <Button
                    canOpenUpgrade
                    startIcon={<AddIcon />}
                    btnType="primary"
                    label="Add Curve"
                    onClick={handleOpenAddCurveModal}
                  />
                </Protect>
              </Box>
              <ViewWrapper
                loading={curvesLoading}
                error={getCurvesErrored}
                loaderPlacement="center"
              >
                {/* show the table only when there are curves present */}
                {currentOrgCurveGroups?.length ? (
                  <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer
                      classes={{
                        root: cn("h-[calc(100vh_-_405px)]"),
                      }}
                      component={Paper}
                    >
                      <Table stickyHeader>
                        <TableHead
                          classes={{ root: styles.classes.tableHeader }}
                        >
                          <TableRow>
                            {CURVES_TABLE_COLUMNS?.map((column, idx) => {
                              if (column.id === "action") {
                                return (
                                  <StickyTableCell
                                    key={idx}
                                    direction="right"
                                    fixedColumnWidth={80}
                                    align="center"
                                    highZIndex
                                  >
                                    {column.label}
                                  </StickyTableCell>
                                );
                              }
                              return (
                                <TableCell
                                  key={idx}
                                  align={column.align as "left" | "right"}
                                  style={{ minWidth: column.minWidth }}
                                >
                                  <TableSortLabel
                                    active={sortTable.orderBy === column.id}
                                    direction={
                                      sortTable.orderBy === column.id
                                        ? sortTable.order
                                        : "asc"
                                    }
                                    onClick={() => sortRows(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* when no curves found */}
                          {visibleRows?.length === 0 && (
                            <TableRow>
                              <TableCell
                                align="center"
                                colSpan={CURVES_TABLE_COLUMNS?.length}
                              >
                                No curves found.
                              </TableCell>
                            </TableRow>
                          )}
                          {visibleRows.map((curve, idx) => {
                            return (
                              <TableRow
                                key={idx}
                                classes={{ root: cn("!cursor-pointer") }}
                                onClick={() =>
                                  navigateToCurveDetailPage(
                                    curve?.curve_group,
                                    curve?.uuid,
                                  )
                                }
                              >
                                <TableCell>
                                  {trimString(curve?.name, 30)}
                                </TableCell>
                                <TableCell>
                                  {
                                    CURVE_TYPE[
                                      curve?.curve_type as keyof typeof CURVE_TYPE
                                    ]
                                  }
                                </TableCell>
                                <TableCell>
                                  {
                                    PERIODICITY[
                                      curve?.periodicity as keyof typeof PERIODICITY
                                    ]
                                  }
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      className={cn("bg-table-gray")}
                      rowsPerPageOptions={[10, 25, 50]}
                      component="div"
                      count={filteredRows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                ) : (
                  <Box
                    className=" min-h-[calc(100vh_-_400px)] 
                  
                  flex justify-center items-center"
                  >
                    {/* when there are no curves show this message */}
                    <Box className={styles.classes.emptyContainer}>
                      <Typography>No curves</Typography>
                    </Box>
                  </Box>
                )}
              </ViewWrapper>
            </Box>
          )}
        </Box>
      </Box>

      <CurveGroupFormModal
        form={addCurveGroupForm}
        setForm={setAddCurveGroupForm}
        headerLabel="Add Curve Group"
        loading={addCurveGroupLoading}
        onClose={handleCloseAddGroupModal}
        onConfirm={onAddGroup}
        open={addGroupModalOpen}
        setFormErrors={SetAddCurveGroupFormErrors}
        formErrors={addCurveGroupFormErrors}
      />

      <CurveGroupFormModal
        form={editCurveGroupForm}
        setForm={setEditCurveGroupForm}
        headerLabel="Edit Curve Group"
        loading={editCurveGroupLoading}
        onClose={handleCloseEditGroupModal}
        onConfirm={onEditGroup}
        open={editGroupModalOpen}
        setFormErrors={setEditCurveGroupFormErrors}
        formErrors={editCurveGroupFormErrors}
      />

      <CurveFormModal
        form={curveForm}
        setForm={setCurveForm}
        headerLabel="Add Curve"
        loading={addCurveLoading}
        onClose={handleCloseAddCurveModal}
        onConfirm={onAddCurve}
        open={addCurveModalOpen}
        setFormErrors={SetAddCurveFormErrors}
        formErrors={addCurveFormErrors}
        org_groups={curveGroupsOptions}
        date_schedule={dateSchedule}
        loadingDateSchedule={loadingDateSchedule}
        onAddGroup={handleOpenAddGroupModal}
        allowEdit
      />
    </>
  );
}
