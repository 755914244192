import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import useStyles from "./styles";
import Button from "../../../components/button";
import ProjectFormModal from "../../../components/project-form-modal";
import ViewWrapper from "../../../components/view-wrapper";
import SummaryTable from "../../../components/summary-table";
import LogsButton from "../../../components/logs-button";
import Chip from "../../../components/general/Chip";
import AddDealFormModal from "../../../components/add-deal-form-modal";
import ProjectDuplicateModal from "../../../components/project-duplicate-modal";
import Logs from "../../../components/logs";
import LogsWrapper from "../../../components/logs-wrapper";
import ChartWrapper from "../../../components/chart-wrapper";
import CheckboxInput from "../../../components/checkbox-input";
import ActionButton from "../../../components/action-button";
import ConditionalProtect from "../../../components/conditional-protect";
import Chart from "../pro-forma/dashboard/chart";
import Map from "../../../components/map";
import TimingChart, {
  ITimingChartProps,
} from "../../../components/timing-chart";
import { getCounties } from "../../../apis/common";
import { deleteProject } from "../../../apis/project/base";
import {
  setCurrentProjectAction,
  updateShowUsageLimitHitModalAction,
  setDeleteModalPropsAction,
} from "../../../utils/redux/slices";
import {
  useAPI,
  useLogs,
  useDrawer,
  useAppSelector,
} from "../../../utils/hooks";
import {
  cn,
  trimString,
  sortArrayOfObjects,
  getStatusChipFillColor,
  getFormattedProjectLocations,
  replacePagePathForPersonalAccount,
} from "../../../utils/helpers";
import {
  DEAL_STAGES,
  DEAL_STATUS,
  FORM_TYPES,
  DEAL_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  ADD_DEAL_FORM_DEFAULT_VAULES,
  PROJECT_FORM_DEFAULT_STATE,
  DEAL_FORM_DEFAULT_STATE,
  PROFORMA_CHART_DETAIL_CONFIG,
} from "../../../constants";
import {
  IProject,
  IProjectTiming,
  ServerPaginatedResponse,
  ITableSort,
  IAddDealForm,
  IAddDealFormErrors,
  ISelectOption,
  IProjectForm,
  IProjectFormErrors,
  IAddUpdateProjectResponse,
  IDuplicateProjectForm,
  IDuplicateProjectFormErrors,
  IGetProjectsParams,
  ProformaReportType,
  IAddDealResponse,
  IDuplicateProjectResponse,
  IDealDetailsInProjectAPI_Call,
  ILogsConfiguration,
  IReportTable,
  IProjectProformaResponse,
  IProjectGeneralSummary,
  IProjectLocation,
  ICounty,
} from "../../../interfaces";

const dealsOfProjectColumns = [
  { id: "name", label: "Deal Name", minWidth: 100, align: "left" },
  { id: "stage", label: "Stage", minWidth: 20, align: "left" },
  { id: "structure", label: "Structure", minWidth: 20, align: "left" },
  {
    id: "tax_credit_structure",
    label: "Tax Credit Structure",
    minWidth: 20,
    align: "left",
  },
  {
    id: "type_string",
    label: "Project Type(s)",
    minWidth: 20,
    align: "left",
  },
  { id: "capacity_ac", label: "Capacity (AC)", minWidth: 20, align: "left" },
  { id: "status", label: "Status", minWidth: 20, align: "left" },
  { id: "modified", label: "Last Modified", minWidth: 20, align: "left" },
];

interface IProps {
  getProjectGeneralSummary: (
    projectUuid: string,
  ) => Promise<IProjectGeneralSummary>;
  getProjectTiming: (projectUuid: string) => Promise<IProjectTiming[] | null>;
  duplicateProject: (
    projectUuid: string,
    form: IDuplicateProjectForm,
  ) => Promise<IDuplicateProjectResponse>;
  updateProject: (
    projectUuid: string,
    form: IProjectForm,
  ) => Promise<IAddUpdateProjectResponse>;
  addDeal: (form: IAddDealForm) => Promise<IAddDealResponse>;
  addProject: (form: IProjectForm) => Promise<IAddUpdateProjectResponse>;
  getProjects: (
    params: IGetProjectsParams,
  ) => Promise<ServerPaginatedResponse<IProject[]>>;
  getProjectProforma: (
    projectUuid: string,
    periodicity: ProformaReportType,
    cash_basis: "cash" | "accrual",
  ) => Promise<IProjectProformaResponse | null>;
  getProjectLocations: (
    status: string,
    projectUuid: string,
  ) => Promise<IProjectLocation[]>;
}

export default function ProjectGeneralView({
  getProjectGeneralSummary,
  getProjectTiming,
  duplicateProject,
  updateProject,
  addDeal,
  addProject,
  getProjects,
  getProjectProforma,
  getProjectLocations,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { organization } = useOrganization();

  const dispatch = useDispatch();
  const { ctrlPressed } = useAppSelector((s) => s.common);
  const { productUsageDetails } = useAppSelector((s) => s.org);
  const { currentProject: project, currentProjectStatus: projectStatus } =
    useAppSelector((s) => s.project);

  const navigate = useNavigate();
  const { projectUuid } = useParams();

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [showLoader, setShowLoader] = React.useState<boolean>(true);
  const [openUpdateProjectModal, setOpenUpdateProjectModal] =
    React.useState<boolean>(false);
  const [projectTiming, setProjectTiming] = React.useState<IProjectTiming>();

  const [openDuplicateProjectModal, setOpenDuplicateProjectModal] =
    React.useState<boolean>(false);
  const [projectDuplicateForm, setDuplicateProjectForm] =
    React.useState<IDuplicateProjectForm>({
      name: "",
    });
  const [projectForm, setProjectForm] = React.useState<IProjectForm>(
    PROJECT_FORM_DEFAULT_STATE,
  );

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });

  const [projectOptions, setProjectOptions] = React.useState<ISelectOption[]>(
    [],
  );

  const [addDealFormData, setAddDealFormData] = React.useState<IAddDealForm>(
    DEAL_FORM_DEFAULT_STATE,
  );

  const [openAddDealModal, setOpenAddDealModal] =
    React.useState<boolean>(false);
  const [addProjectModalOpen, setAddProjectModalOpen] =
    React.useState<boolean>(false);

  const [projectProforma, setProjectProforma] =
    React.useState<IReportTable | null>(null);

  const [showOperationPeriod, setShowOperationPeriod] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if ((!projectStatus.loading && project) || projectStatus.errored) {
      setShowLoader(false);
    }

    if (!projectStatus.loading && project) {
      getCountiesCallAPI({
        search: "",
        state: project?.state,
      });
    }
  }, [projectStatus]);

  React.useEffect(() => {
    getProjectGeneralSummaryCallAPI(projectUuid);

    getProjectProformaCallAPI().then((response) => {
      response && setProjectProforma(response.data);
    });
  }, [projectUuid]);

  React.useEffect(() => {
    getSummaryDataCallAPI(projectUuid);
    getProjectLocationsCallAPI(projectUuid);
  }, [projectUuid, project?.county]);

  const {
    callAPI: getProjectGeneralSummaryCallAPI,
    errored: getProjectGeneralSummaryErrored,
    loading: loadingProjectGeneralSummary,
    response: projectGeneralSummary,
  } = useAPI((projectUuid: string) => getProjectGeneralSummary(projectUuid), {
    initialLoading: true,
  });

  const { callAPI: getProjectsCallAPI } = useAPI(() => getProjects({}));
  const { callAPI: deleteProjectCallAPI } = useAPI(() =>
    deleteProject(projectUuid),
  );

  const {
    callAPI: addDealCallAPI,
    fieldErrors: addDealFormErrors,
    setFieldErrors: setAddDealFormErrors,
    loading: addDealLoading,
  } = useAPI<IAddDealResponse, IAddDealFormErrors>((form: IAddDealForm) =>
    addDeal(form),
  );

  const {
    callAPI: updateProjectCallAPI,
    fieldErrors: updateProjectFormErrors,
    setFieldErrors: setUpdateProjectFormErrors,
    loading: updateProjectLoading,
  } = useAPI<IAddUpdateProjectResponse, IProjectFormErrors>(
    (projectUuid: string, form: IProjectForm) =>
      updateProject(projectUuid, form),
  );

  const {
    callAPI: duplicateProjectCallAPI,
    fieldErrors: duplicateProjectFormErrors,
    setFieldErrors: setDuplicateProjectFormErrors,
    loading: duplicateProjectLoading,
  } = useAPI<IDuplicateProjectResponse, IDuplicateProjectFormErrors>(
    (projectUuid: string, form: IDuplicateProjectForm) =>
      duplicateProject(projectUuid, form),
  );

  const {
    callAPI: getSummaryDataCallAPI,
    loading: loadingSummary,
    errored: getSummaryErrored,
  } = useAPI((projectUuid) => getSummaryData(projectUuid), {
    initialLoading: true,
  });

  const {
    callAPI: getProjectProformaCallAPI,
    loading: loadingProjectProforma,
    errored: getProjectProformaFailed,
  } = useAPI(() => getProjectProforma(String(projectUuid), "AN", "cash"), {
    initialLoading: true,
  });

  const {
    callAPI: addProjectCallAPI,
    fieldErrors: addProjectFormErrors,
    setFieldErrors: setAddProjectFormErrors,
    loading: addProjectLoading,
  } = useAPI<IAddUpdateProjectResponse, IProjectFormErrors>(
    (form: IProjectForm) => addProject(form),
  );

  const {
    callAPI: getProjectLocationsCallAPI,
    response: projectLocations,
    loading: loadingProjectLocations,
    errored: getProjectLocationsFailed,
  } = useAPI((projectUuid: string) => getProjectLocations("ACT", projectUuid), {
    initialLoading: true,
  });

  const { callAPI: getCountiesCallAPI, response: counties } = useAPI(
    (params) => getCounties(params.search, params.state),
    {
      initialLoading: true,
    },
  );

  const getSummaryData = async (projectUuid: string) => {
    await Promise.all([handleGetProjectTiming(projectUuid)]).then(() => {});
  };

  const handleGetProjectTiming = async (projectUuid: string) => {
    const response = await getProjectTiming(projectUuid).catch(() => null);
    if (response) {
      setProjectTiming(response[0]);
      if (response[0]?.horizon === "OS") {
        setShowOperationPeriod(true);
      }
    }
    return response;
  };

  const sortRows = (orderBy: string) => {
    if (orderBy === sortTable.orderBy) {
      setSortTable({
        orderBy,
        order: sortTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const goToDeal = (dealUuid: string) => {
    window.open(`/deal/${dealUuid}/case-deal/${dealUuid}/general`, "_blank");
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleUpdateProject = async (form: IProjectForm) => {
    const updatedProject = await updateProjectCallAPI(projectUuid, form);

    if (project && updatedProject) {
      dispatch(
        setCurrentProjectAction({
          ...project,
          ...updatedProject,
        }),
      );

      await getProjectGeneralSummaryCallAPI(projectUuid);
    }

    return updatedProject;
  };

  const handleCloseDuplicateProjectModal = () => {
    setOpenDuplicateProjectModal(false);
  };

  const handleCloseUpdateProjectModal = () => {
    setOpenUpdateProjectModal(false);
  };

  const handleDuplicateProject = async (form: IDuplicateProjectForm) => {
    const project = await duplicateProjectCallAPI(projectUuid, form);

    if (project) {
      navigate(`/project/${project.uuid}/general`);
      toast.success("Project duplicated successfully!", {
        toastId: "project-duplication-success",
      });
    }

    return project;
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () =>
      sortArrayOfObjects(
        project?.deals || [],
        sortTable?.orderBy,
        sortTable?.order,
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [project?.deals, sortTable, page, rowsPerPage],
  );

  const handleOpenAddDealModal = async () => {
    if (productUsageDetails?.strawman_plan === "TRIAL") {
      dispatch(updateShowUsageLimitHitModalAction(true));
      return;
    }

    if (!projectOptions.length) {
      const projectList = await getProjectsCallAPI();
      setProjectOptions(
        (projectList?.results || []).map((p) => ({
          label: p.name,
          value: String(p.uuid),
          status: p.status,
        })),
      );
    }

    setAddDealFormData((prev) => ({
      ...ADD_DEAL_FORM_DEFAULT_VAULES,
      project_uuids: [String(projectUuid)],
    }));
    setOpenAddDealModal(true);
  };

  const handleAddDeal = async (form: IAddDealForm) => {
    const deal = await addDealCallAPI(form);

    if (deal) {
      const { project_count, projects, ...dealDetails } = deal;
      const dealData: IDealDetailsInProjectAPI_Call = {
        ...dealDetails,
        // TODO - Fix this, assuming these fields in forntend
        abc_file_path: "",
        capacity_ac: 0,
        case_uuid: null,
        parent_deal_uuid: null,
        tax_credit_type: "NONE",
        type_string: "",
        status: "ACT",
      };
      if (project) {
        dispatch(
          setCurrentProjectAction({
            ...project,
            deals: [...project.deals, { ...dealData }],
          }),
        );
      }
    }

    deal && goToDeal(deal.uuid);
    return deal;
  };

  const handleOpenAddProjectModal = () => {
    if (productUsageDetails?.strawman_plan === "TRIAL") {
      dispatch(updateShowUsageLimitHitModalAction(true));
      return;
    }
    setAddProjectModalOpen(true);
  };

  const handleCloseAddProjectModal = () => {
    setAddProjectModalOpen(false);
  };

  const handleShowOperationPeriodToggle = () => {
    setShowOperationPeriod((s) => !s);
  };

  const handleOpenDuplicateProjectModal = () => {
    const { uuid, modified, created, county, ...formDetails } = project!;
    setDuplicateProjectForm({ ...formDetails });
    setOpenDuplicateProjectModal(true);
  };

  const handleOpenUpdateProjectModal = () => {
    if (project) {
      const { uuid, modified, created, county_name, county, ...formDetails } =
        project;
      setProjectForm({
        ...formDetails,
        county: county !== null ? String(county) : "",
      });
      setOpenUpdateProjectModal(true);
    }
  };

  const goToProject = (projectUuid: string) => {
    if (ctrlPressed) {
      window.open(`/project/${projectUuid}/general/`, "_blank");
      return;
    }
    navigate(`/project/${projectUuid}/general/`);
  };

  const handleAddProject = async (form: IProjectForm) => {
    const project = await addProjectCallAPI(form);
    if (project) {
      if (openAddDealModal) {
        setAddDealFormData((prev) => ({
          ...prev,
          project_uuids: [...prev.project_uuids, project.uuid],
        }));
        setProjectOptions((prev) => [
          ...prev,
          {
            label: project.name,
            value: project.uuid,
            status: project.status,
          },
        ]);
        return project;
      }
      goToProject(project.uuid);
    }
    return project;
  };

  const timingChartData: ITimingChartProps | undefined = React.useMemo(() => {
    if (projectTiming) {
      const {
        horizon,
        cod,
        start_date,
        end_date,
        ntp_date,
        mechanical_completion_date,
        placed_in_service_date,
        substantial_completion_date,
      } = projectTiming;

      const dataPoints = [
        // {
        //   date: new Date(start_date),
        //   color: "#FFA700",
        //   symbol: "rect",
        //   tooltip: "Start Date",
        //   id: "start",
        // },
        {
          date: new Date(ntp_date),
          color: "#F44336",
          symbol: "rectRot",
          tooltip: "Notice to Proceed",
          id: "ntp",
        },
        {
          date: new Date(mechanical_completion_date),
          color: "#00B7C3",
          symbol: "triangle",
          tooltip: "Mechanical Completion Date",
          rotation: 180,
          id: "mc",
        },
        {
          date: new Date(substantial_completion_date),
          color: "#007EC5",
          symbol: "triangle",
          tooltip: "Substantial Completion Date",
          id: "sc",
        },
        {
          date: new Date(placed_in_service_date),
          color: "#AB47BC",
          symbol: "circle",
          tooltip: "Placed In Service Date",
          id: "pis",
        },
        {
          date: new Date(cod),
          color: "#00B96A",
          symbol: "rect",
          tooltip: "COD",
          id: "cod",
        },
        ...(showOperationPeriod
          ? [
              {
                date: new Date(end_date),
                color: "#F44336",
                symbol: "circle",
                tooltip: "End Date",
                id: "end",
              },
            ]
          : []),
      ];

      const filteredDataPoints = dataPoints.filter((d) => {
        return horizon === "OS" ? d.id === "cod" || d.id === "end" : true;
      });

      return {
        startDate: new Date(start_date),
        endDate: new Date(end_date),
        data: filteredDataPoints,
      };
    }
    return undefined;
  }, [projectTiming, showOperationPeriod]);

  const navigateActionButton = (url: string) => {
    let urlToUse = url;

    if (!organization) {
      urlToUse = replacePagePathForPersonalAccount(
        urlToUse,
        "project",
      ) as string;
    }

    return (
      <IconButton onClick={() => navigate(urlToUse)}>
        <ArrowCircleRightIcon />
      </IconButton>
    );
  };

  const projectLogConfiguration: ILogsConfiguration = {
    id: String(projectUuid),
    type: "project",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(projectLogConfiguration.type, projectLogConfiguration.id);
  };

  const handleDeleteProjectConfirm = async () => {
    const response = await deleteProjectCallAPI(Number(projectUuid));
    if (response) {
      toast.success("Project deleted successfully!", {
        toastId: "project-deletion-success",
      });
      navigate("/project-list");
    }
  };

  const handleDeleteProject = () => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Project",
        description: "Are you sure you want to delete this project?",
        onConfirm: handleDeleteProjectConfirm,
      }),
    );
  };

  const mapLocation = React.useMemo(() => {
    if (!projectLocations) return [];
    return getFormattedProjectLocations(projectLocations as IProjectLocation[]);
  }, [projectLocations]);

  const countyOptions = React.useMemo(() => {
    const results = counties?.results.map((county) => ({
      label: county.name,
      value: String(county.id),
    }));
    return (results as unknown as ISelectOption[]) || ([] as ISelectOption[]);
  }, [projectForm.state, counties]);

  return (
    <ViewWrapper loading={showLoader} error={projectStatus.errored}>
      <Box className={cn("flex justify-end mb-4")}>
        <Box className={cn("flex gap-4 items-center")}>
          <LogsButton onClick={handleOnOpenLogs} />

          <ConditionalProtect type="project">
            <ActionButton
              canOpenUpgrade
              actionType="add"
              onClick={handleOpenAddDealModal}
            >
              Add to new Deal
            </ActionButton>
          </ConditionalProtect>
          <ConditionalProtect type="project">
            <ActionButton
              canOpenUpgrade
              actionType="duplicate"
              dataPw="duplicate"
              onClick={handleOpenDuplicateProjectModal}
            >
              Duplicate
            </ActionButton>
            <ActionButton
              canOpenUpgrade
              actionType="edit"
              dataPw="edit"
              onClick={handleOpenUpdateProjectModal}
            >
              Edit
            </ActionButton>
          </ConditionalProtect>

          {project?.status === "ARCH" && (
            <ConditionalProtect type="data_delete">
              <ActionButton
                canOpenUpgrade
                actionType="delete"
                onClick={handleDeleteProject}
              >
                Delete Project
              </ActionButton>
            </ConditionalProtect>
          )}
        </Box>
      </Box>

      <ViewWrapper
        loading={loadingProjectGeneralSummary}
        error={getProjectGeneralSummaryErrored}
      >
        <Box className={cn("mb-2 flex gap-2")}>
          {projectGeneralSummary?.data.tables.map((table, idx) => {
            return (
              <SummaryTable
                key={idx}
                usedIn="project"
                summaryTable={table}
                onEditClick={
                  idx === 0 ? handleOpenUpdateProjectModal : undefined
                }
              />
            );
          })}
        </Box>
      </ViewWrapper>

      <ViewWrapper loading={loadingSummary} error={getSummaryErrored}>
        {timingChartData && (
          <ChartWrapper
            title="Timing"
            primaryButton={navigateActionButton(
              `/project/${projectUuid}/timing`,
            )}
          >
            <Box>
              <CheckboxInput
                checked={showOperationPeriod}
                label="Show Operations Period"
                onChange={handleShowOperationPeriodToggle}
              />
              <TimingChart {...timingChartData} />
            </Box>
          </ChartWrapper>
        )}
      </ViewWrapper>

      <ViewWrapper
        loading={loadingProjectProforma}
        error={getProjectProformaFailed}
      >
        {projectProforma ? (
          <Box>
            <Chart
              chartData={projectProforma}
              showOnlySummary={true}
              chartElementConfig={PROFORMA_CHART_DETAIL_CONFIG}
              selectedBasis="cash"
              hideMwAxis={project?.energy_type === "BESS"}
              primaryButton={navigateActionButton(
                `/project/${projectUuid}/pro-forma`,
              )}
            />
          </Box>
        ) : null}
      </ViewWrapper>

      <ViewWrapper
        loading={projectStatus.loading || loadingSummary}
        error={projectStatus.errored || getSummaryErrored}
      >
        <div className={styles.classes.mapZoomButtons}>
          <Map data={mapLocation} disablePopup zoom={9} />
        </div>
      </ViewWrapper>

      {organization ? (
        <ViewWrapper
          loading={projectStatus.loading}
          error={projectStatus.errored}
        >
          <Box
            id="project-deals"
            className={cn("flex justify-between items-center")}
          >
            <Typography variant="h6">
              Deals associated with this Project
            </Typography>
            <ConditionalProtect type="project">
              <Button
                canOpenUpgrade
                startIcon={<AddIcon />}
                btnType="primary"
                label="Add to new Deal"
                onClick={handleOpenAddDealModal}
              />
            </ConditionalProtect>
          </Box>
          <Paper
            sx={{ width: "100%", overflow: "hidden" }}
            className={cn("mt-4")}
          >
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead className={styles.classes.tableHead}>
                  <TableRow>
                    {dealsOfProjectColumns.map((column, idx) => {
                      return (
                        <TableCell
                          key={idx}
                          align={column?.align as "left" | "right"}
                          style={{ minWidth: column?.minWidth }}
                        >
                          <TableSortLabel
                            active={sortTable.orderBy === column?.id}
                            direction={
                              sortTable.orderBy === column?.id
                                ? sortTable.order
                                : "asc"
                            }
                            onClick={() => sortRows(column?.id)}
                          >
                            {column?.label}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {project?.deals?.length === 0 && (
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={dealsOfProjectColumns.length}
                      >
                        There are no Deals.
                        <br />
                        <ConditionalProtect type="project">
                          <Link
                            component="button"
                            onClick={handleOpenAddDealModal}
                          >
                            Add to new Deal
                          </Link>
                        </ConditionalProtect>
                      </TableCell>
                    </TableRow>
                  )}
                  {visibleRows?.map((deal, idx) => {
                    return (
                      <TableRow
                        hover
                        key={idx}
                        tabIndex={-1}
                        className={cn("cursor-pointer")}
                        onClick={() => goToDeal(deal?.uuid)}
                      >
                        <TableCell align="left">
                          {trimString(deal?.name, 40)}
                        </TableCell>
                        <TableCell align="left">
                          {DEAL_STAGES[deal?.stage as keyof typeof DEAL_STAGES]}
                        </TableCell>
                        <TableCell align="left">
                          {
                            DEAL_STRUCTURE_TYPE[
                              deal?.structure as keyof typeof DEAL_STRUCTURE_TYPE
                            ]
                          }
                        </TableCell>
                        <TableCell align="left">
                          {
                            DEAL_TAX_CREDIT_STRUCTURE_TYPE[
                              deal?.tax_credit_structure as keyof typeof DEAL_TAX_CREDIT_STRUCTURE_TYPE
                            ]
                          }
                        </TableCell>
                        <TableCell align="left">{deal?.type_string}</TableCell>
                        <TableCell align="left">
                          {deal?.capacity_ac} MW
                        </TableCell>
                        <TableCell align="left">
                          <Chip
                            label={DEAL_STATUS[deal.status]}
                            color={getStatusChipFillColor(deal.status).color}
                            filledBackgroundColor={
                              getStatusChipFillColor(deal.status)
                                .backgroundColor
                            }
                            variant="filled"
                          />
                        </TableCell>
                        <TableCell align="left">
                          {format(new Date(deal?.modified), "M/d/yyyy")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={project?.deals?.length || 0}
              className={styles.classes.paginationRow}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </ViewWrapper>
      ) : null}

      <ProjectFormModal
        open={openUpdateProjectModal}
        loading={updateProjectLoading}
        onClose={handleCloseUpdateProjectModal}
        formErrors={updateProjectFormErrors}
        setFormErrors={setUpdateProjectFormErrors}
        form={projectForm}
        setForm={setProjectForm}
        headerLabel="Edit project details"
        onConfirm={handleUpdateProject}
        hide={["energy_type"]}
        countyOptions={countyOptions || []}
        onStateChange={(value) => {
          getCountiesCallAPI({
            search: "",
            state: value,
          });
        }}
      />

      <ProjectDuplicateModal
        open={openDuplicateProjectModal}
        loading={duplicateProjectLoading}
        onClose={handleCloseDuplicateProjectModal}
        formErrors={duplicateProjectFormErrors}
        setFormErrors={setDuplicateProjectFormErrors}
        form={projectDuplicateForm}
        setForm={setDuplicateProjectForm}
        headerLabel="Duplicate Project"
        onConfirm={handleDuplicateProject}
      />

      <AddDealFormModal
        open={openAddDealModal}
        formErrors={addDealFormErrors}
        loading={addDealLoading}
        setFormErrors={setAddDealFormErrors}
        form={addDealFormData}
        setForm={setAddDealFormData}
        onClose={() => setOpenAddDealModal(false)}
        existingProjects={projectOptions}
        onAdd={handleAddDeal}
        openAddProjectModal={handleOpenAddProjectModal}
      />

      <ProjectFormModal
        open={addProjectModalOpen}
        headerLabel="Add a new Project"
        form={projectForm}
        loading={addProjectLoading}
        setForm={setProjectForm}
        formErrors={addProjectFormErrors}
        setFormErrors={setAddProjectFormErrors}
        onClose={handleCloseAddProjectModal}
        onConfirm={handleAddProject}
        formType={FORM_TYPES.CREATE}
        countyOptions={countyOptions || []}
        onStateChange={(value) => {
          getCountiesCallAPI({
            search: "",
            state: value,
          });
        }}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={projectLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={projectLogConfiguration.id}
        />
      </LogsWrapper>
    </ViewWrapper>
  );
}
