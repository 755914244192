/* eslint-disable @typescript-eslint/no-explicit-any */
import { twMerge } from "tailwind-merge";
import { clsx, type ClassValue } from "clsx";
import { compareDesc, isValid } from "date-fns";
import { cloneDeep, isNaN, isString, uniqueId } from "lodash";
import {
  GridColDef,
  GridRowModel,
  GridRowsProp,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";

import {
  IDealDebt,
  IDealDetails,
  ISidebarLink,
  IProformaChartDetailField,
  VisibilityFilterType,
  IDealOutputPageTabs,
  ISummaryTable,
  ITab,
} from "../../interfaces";

export const numberToUSD = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 6,
});

export const formatCurrency = (amount: number, fractionDigits = 0) => {
  const actualDecimals = amount.toString().split(".")[1]?.length || 0; // will remove trailing zeros
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: Math.min(actualDecimals, fractionDigits),
    minimumFractionDigits: Math.min(actualDecimals, fractionDigits),
  }).format(amount);
};

export const numberWithCommas = ((formatter) => ({
  ...formatter,
  format: (num: number) => {
    const formattedNum = formatter.format(num);
    return formattedNum === "-0" ? "0" : formattedNum; // to avoid returning -0
  },
}))(
  new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 0,
  }),
);

export const numberWithDecimalsAndCommas = (
  num: number,
  decimalPlaces?: number,
) => {
  const actualDecimals = num.toString().split(".")[1]?.length || 0; // will remove trailing zeros
  const formattedNum = num.toLocaleString("en-US", {
    minimumFractionDigits: Math.min(actualDecimals, decimalPlaces ?? 2),
    maximumFractionDigits: Math.min(actualDecimals, decimalPlaces ?? 2),
  });
  return formattedNum === "-0" ? "0" : formattedNum;
};

export const formatNumberWithDecimals = (
  num: number,
  maximumFractionDigits: number,
) => {
  return num.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionDigits,
  });
};

export const formatNumberWithDollarAndDecimals = (
  num: number,
  decimalPlaces: number = 2,
) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(num);
};

export const trimString = (str: string | null | undefined, len: number) => {
  if (str && str.length > len) {
    return str.substring(0, len) + "...";
  }
  return str;
};

export function enumToOptions<T extends Record<string, string>>(
  enumInput: T,
  disabledOptions: Array<keyof T> = [],
) {
  return Object.keys(enumInput).map((key) => ({
    label: enumInput[key],
    value: key,
    disabled: disabledOptions?.includes(key),
  }));
}

export const getFilterByVisibilityMatchedObjKeyCount = (
  operator: { [k in string]?: (string | boolean)[] },
  conditions: { [k in string]?: string | boolean },
) => {
  let matchedObjKeyCount = 0;
  Object.entries(operator).forEach(([key, value]) => {
    Object.entries(conditions).forEach(([fKey, fValue]) => {
      if (key === fKey && value?.includes(fValue as boolean | string)) {
        matchedObjKeyCount += 1;
      }
    });
  });

  return matchedObjKeyCount;
};

export const getFilteredObj = (
  item: any,
  operators: VisibilityFilterType<{ [k: string]: never }>,
  conditions: { [k: string]: string | boolean },
  currentDeal?: IDealDetails,
  disableItem?: boolean,
) => {
  const { AND, NOT, OR } = operators;

  if (AND) {
    const conditionObjKeyCount = Object.keys(AND).length;
    const matchedObjKeyCount = getFilterByVisibilityMatchedObjKeyCount(
      AND,
      conditions,
    );

    if (disableItem) {
      if (conditionObjKeyCount === matchedObjKeyCount) {
        item.disabled = true;
      }
      return item;
    }

    if (conditionObjKeyCount === matchedObjKeyCount) {
      return item;
    } else if (!OR && !NOT) {
      return null;
    }
  }

  if (OR) {
    const matchedObjKeyCount = getFilterByVisibilityMatchedObjKeyCount(
      OR,
      conditions,
    );

    if (disableItem) {
      if (matchedObjKeyCount) {
        item.disabled = true;
      }
      return item;
    }

    if (matchedObjKeyCount) {
      return item;
    } else if (!NOT) {
      return null;
    }
  }

  if (NOT) {
    const { AND: AND_OF_NOT, OR: OR_OF_NOT } = NOT;

    if (AND_OF_NOT) {
      const conditionObjKeyCount = Object.keys(AND_OF_NOT).length;
      const matchedObjKeyCount = getFilterByVisibilityMatchedObjKeyCount(
        AND_OF_NOT,
        conditions,
      );

      if (disableItem) {
        if (conditionObjKeyCount !== matchedObjKeyCount) {
          item.disabled = true;
        }
        return item;
      }

      if (conditionObjKeyCount === matchedObjKeyCount) {
        return null;
      } else {
        return item;
      }
    }

    if (OR_OF_NOT) {
      const matchedObjKeyCount = getFilterByVisibilityMatchedObjKeyCount(
        OR_OF_NOT,
        conditions,
      );

      if (disableItem) {
        if (!matchedObjKeyCount) {
          item.disabled = true;
        }
        return item;
      }

      if (matchedObjKeyCount) {
        return null;
      } else {
        return item;
      }
    }
  }

  return null;
};

export const filterByVisibility = (
  array: any[],
  conditions: { [k: string]: string | boolean },
  nestedItemKey?: string,
  currentDeal?: IDealDetails,
) => {
  return array.map((item) => {
    let itemToReturn = item;

    const removeOnArchived: string[] = ["Analysis", "Output"];

    if (
      currentDeal?.status === "ARCH" &&
      removeOnArchived.includes(item.label)
    ) {
      return null;
    }

    if (item.visibility) {
      itemToReturn = getFilteredObj(
        item,
        item.visibility,
        conditions,
        currentDeal,
      );
    }

    if (item.disable) {
      itemToReturn = getFilteredObj(
        itemToReturn,
        item.disable,
        conditions,
        currentDeal,
        true,
      );
    }

    if (item.visibility || item.disable) {
      return itemToReturn;
    }

    if (nestedItemKey && itemToReturn[nestedItemKey]) {
      const filteredItems = filterByVisibility(
        itemToReturn[nestedItemKey],
        conditions,
        nestedItemKey,
        currentDeal,
      );
      itemToReturn[nestedItemKey] = filteredItems.filter((l) => l);
    }
    return itemToReturn;
  });
};

export const filterSidebarLinks = (
  links: ISidebarLink[],
  filterObj: { [k: string]: string | boolean },
  currentDeal?: IDealDetails,
): ISidebarLink[] => {
  const filteredLinks = filterByVisibility(
    links,
    filterObj,
    "subLinks",
    currentDeal,
  ).filter((l) => l);
  return filteredLinks;
};

export const filterFormFields = <Field>(
  fields: Field[],
  conditions: { [k: string]: string | boolean },
): Field[] => {
  return filterByVisibility(fields, conditions).filter((f) => f);
};

export const keepNumbersDotAndMinus = (input: string) => {
  // replace() 1: Remove all characters except digits, dots, and hyphens
  // replace() 2: Remove consecutive dots
  // replace() 3: Ensure no two consecutive dots and handle cases where hyphen is not at the beginning. Remove hyphens that are not at the start
  let cleanedString = input
    .replace(/[^-.\d]/g, "")
    .replace(/\.{2,}/g, ".")
    .replace(/(?<!^)-/g, "");

  // Handle the case where the string starts with a dot and is followed by digits
  if (cleanedString.startsWith(".")) {
    cleanedString = "0" + cleanedString;
  }

  // Ensure there is only one dot allowed
  const parts = cleanedString.split(".");
  if (parts.length > 2) {
    cleanedString = parts[0] + "." + parts.slice(1).join("");
  }

  // Match the pattern of an optional leading hyphen followed by digits and dots
  const match = cleanedString.match(/^-?\d*\.?\d*$/);
  // Return the matched string or an empty string if no match
  return match ? match[0] : "";
};

export const formatTime = (timestamp: string): string => {
  const isoFormat = timestamp.replace(" ", "T") + "Z";
  const date = new Date(isoFormat);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const getAbbreviatedMonthName = (
  monthNumber: number,
): string | undefined => {
  if (monthNumber < 1 || monthNumber > 12) {
    return undefined;
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return monthNames[monthNumber - 1];
};

export const sortArrayOfObjects = <T>(
  array: T[],
  key: string,
  direction: "asc" | "desc",
  options?: {
    isDollars: (keyof T)[];
  },
): T[] => {
  const sortedArray: any[] = array.slice(0);

  sortedArray.sort((a, b) => {
    const [o, k] = key.split(".");
    let valueA = key?.includes(".") ? a[o][k] : a[key];
    let valueB = key?.includes(".") ? b[o][k] : b[key];
    let comparison = 0;

    if (options?.isDollars.includes(key as keyof T)) {
      valueA = Number(valueA.replaceAll(/[\$,]/g, ""));
      valueB = Number(valueB.replaceAll(/[\$,]/g, ""));
    }

    if (typeof valueA === "string" && typeof valueB === "string") {
      valueA = valueA.toLowerCase();
      valueB = valueB.toLowerCase();

      const dateA = new Date(valueA);
      const dateB = new Date(valueB);

      if (isValid(dateA) && isValid(dateB)) {
        return direction === "desc"
          ? compareDesc(dateA, dateB)
          : compareDesc(dateA, dateB) * -1;
      }
    }

    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return direction === "desc" ? comparison * -1 : comparison;
  });

  return sortedArray as T[];
};

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const generateColorGradient = (
  colorRanges: string[],
  n: number,
): string[] => {
  const gradientColors: string[] = [];

  if (n <= 1) {
    const singleColor = colorRanges[0];
    gradientColors.push(singleColor);
    return gradientColors;
  }

  // Iterate through each color range
  for (let i = 0; i < colorRanges.length - 1; i++) {
    // Convert hex colors to RGB
    const startRGB = hexToRgb(colorRanges[i]);
    const endRGB = hexToRgb(colorRanges[i + 1]);

    // Calculate the step size for each color component
    const step = {
      r: (endRGB.r - startRGB.r) / (n - 1),
      g: (endRGB.g - startRGB.g) / (n - 1),
      b: (endRGB.b - startRGB.b) / (n - 1),
    };

    // Generate the gradient colors for this range
    const rangeColors = Array.from({ length: n }, (_, j) => {
      const r = Math.round(startRGB.r + step.r * j);
      const g = Math.round(startRGB.g + step.g * j);
      const b = Math.round(startRGB.b + step.b * j);
      return rgbToHex(r, g, b);
    });

    // Add the range colors to the overall gradient
    gradientColors.push(...rangeColors);
  }

  return gradientColors;
};

// Function to convert hex color to RGB
const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
  // Remove '#' if present
  hex = hex.replace(/^#/, "");

  // Parse hex into RGB
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
};

// Function to convert RGB to hex color
const rgbToHex = (r: number, g: number, b: number): string => {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

export const getKeyFromEnumObject = (
  value: string,
  enumObj: { [key: string]: string },
): string | undefined => {
  for (const [abbreviation, name] of Object.entries(enumObj)) {
    if (name === value) {
      return abbreviation;
    }
  }
  return undefined; // Return undefined if the key is not found
};

interface IGetLayoutTabsPrarams {
  module: "Projects" | "Deals" | "Configuration" | "Collaboration";
  currentDeal?: IDealDetails;
  currentDealTermDebt?: IDealDebt | null;
  projectValuationExist?: boolean;
  links: ISidebarLink[];
}

export const getLayoutTabs = ({
  module,
  currentDeal,
  currentDealTermDebt,
  projectValuationExist,
  links,
}: IGetLayoutTabsPrarams) => {
  if (module === "Deals") {
    if (!currentDeal) {
      return [];
    }

    links = filterSidebarLinks(cloneDeep(links), {
      structure: currentDeal.structure,
      tax_credit_structure: currentDeal.tax_credit_structure,
      debt: currentDealTermDebt ? "1" : "",
      is_construction_debt_added: currentDeal.is_construction_debt_added,
    });
  }

  if (module === "Projects") {
    links = filterSidebarLinks(cloneDeep(links), {
      project_valuation_exist: Boolean(projectValuationExist),
    });
  }

  return links.map((link) => ({
    label: link.label,
    value: link.value || "",
    path: link.path,
    pathToMatch: link.pathToMatch || "",
    disabled: link.disabled,
  }));
};

export const navigateAndScroll = async (
  cb: () => void,
  elementId: string,
  height: number = 0,
) => {
  cb();
  // Wait for the element to be available
  const waitForElement = async () => {
    return new Promise<HTMLElement | null>((resolve) => {
      const intervalId = setInterval(() => {
        const element = document.getElementById(elementId);
        if (element) {
          clearInterval(intervalId); // Stop checking for the element
          resolve(element);
        }
      }, 100); // Check every 100 milliseconds
    });
  };

  const element = await waitForElement();
  if (element) {
    window.scrollTo({
      top: element.getBoundingClientRect().top + window.scrollY - height,
      behavior: "smooth",
    });
  }
};

export const getProformaChartDetailField = (
  id: string,
  label: string,
  minWidth: number = 80,
  align: "left" | "right" = "right",
  alwaysVisible: boolean = false,
  color: string = "#000000",
): IProformaChartDetailField => {
  return { id, label, color, minWidth, align, alwaysVisible };
};

export function convertDateString(dateStr: string) {
  return dateStr.replaceAll("-", "/");
}

/**
 * Formats a numeric string with locale-specific formatting and specified fraction digits.
 *
 * @param {string} val - The numeric string to be formatted.
 * @returns {string} The formatted numeric string.
 */
export const formatNumberForView = (val: string): string => {
  if (["", "-"].includes(val)) return val;

  const splittedNumber = val.split(".");

  if (val.endsWith("."))
    return Number(splittedNumber[0]).toLocaleString() + ".";

  if (val.includes(".")) {
    const formattedWholeNmber = Number(splittedNumber[0]).toLocaleString();
    return `${formattedWholeNmber}.${splittedNumber[1]}`;
  } else {
    return Number(val).toLocaleString(undefined, {
      minimumFractionDigits: 0,
    });
  }
};

export const calculateCurveTotal = (
  curve: (number | string | null)[],
): number => {
  if (curve.length === 0) return 0;
  const maxDecimalPlace = 6;

  const result = curve.reduce((acc: number, num: string | number | null) => {
    if (num === null) {
      return acc;
    }
    if (isString(num)) {
      num = parseFloat(num);
    }
    if (isNaN(num)) {
      return acc;
    }
    return acc + num;
  }, 0);

  return parseFloat(result.toFixed(maxDecimalPlace));
};

export const isArrayOfStrings = (arr: unknown): arr is string[] => {
  return Array.isArray(arr) && arr.every((item) => typeof item === "string");
};

export const getTableColumnAccordingToStatus = (
  tableColumns: any[],
  projectStatus: string,
) => {
  if (projectStatus === "ARCH") return tableColumns;
  return [
    ...tableColumns,
    isArrayOfStrings(tableColumns)
      ? "Action"
      : { id: "action", label: "Action", minWidth: 80, align: "right" },
  ];
};

export const transformBlobToFile = (res: Blob, fileName: string) => {
  const contentBlob = new Blob([res], {
    type: "application/octet-stream",
  });
  const link = document.createElement("a");
  link.setAttribute("href", window.URL.createObjectURL(contentBlob));
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const replaceCaseIdAttachedDealPagePath = (path: string) => {
  return path
    .replace("/deal/", "/personal/deal/")
    .replace("/case-deal/undefined", "");
};

export const replacePagePathForPersonalAccount = (
  path: string | undefined,
  linkType: "deal" | "project",
) => {
  if (path) {
    if (linkType === "deal") {
      return path
        .replace("/deal/", "/personal/deal/")
        .replace("/case-deal/:caseDealUuid", "");
    } else {
      return path.replace("/project/", "/personal/project/");
    }
  }
  return path;
};

export const transformExistingSidebarLinksForPersoalAccount = (
  links: ISidebarLink[],
  linkType: "deal" | "project",
  transformedLinks: ISidebarLink[],
): ISidebarLink[] => {
  links.forEach((link) => {
    if (linkType === "deal") {
      const newLinkObj: ISidebarLink = {
        label: link.label,
        path: replacePagePathForPersonalAccount(link.path, "deal") as string,
        pathToMatch: replacePagePathForPersonalAccount(
          link.pathToMatch,
          "deal",
        ),
        icon: link.icon,
        disabled: link.disabled,
        value: link.value,
        disable: link.disable,
        visibility: link.visibility,
      };

      if (link.subLinks) {
        newLinkObj.subLinks = transformExistingSidebarLinksForPersoalAccount(
          link.subLinks,
          linkType,
          [],
        );
      }

      transformedLinks.push(newLinkObj);
    }

    if (linkType === "project") {
      const newLinkObj: ISidebarLink = {
        label: link.label,
        path: replacePagePathForPersonalAccount(link.path, "project") as string,
        pathToMatch: replacePagePathForPersonalAccount(
          link.pathToMatch,
          "project",
        ),
        icon: link.icon,
        disabled: link.disabled,
        value: link.value,
        disable: link.disable,
        visibility: link.visibility,
      };

      if (link.subLinks) {
        newLinkObj.subLinks = transformExistingSidebarLinksForPersoalAccount(
          link.subLinks,
          linkType,
          [],
        );
      }

      transformedLinks.push(newLinkObj);
    }
  });

  return transformedLinks;
};

export const generateSidebarForPersoalAccount = (
  existingSidebarLinks: ISidebarLink[],
  type: "deal" | "project",
) => {
  const transformedLinks = transformExistingSidebarLinksForPersoalAccount(
    existingSidebarLinks,
    type,
    [],
  );

  if (type === "deal") {
    return transformedLinks.filter(
      (l) =>
        l.label !== "divider" && l.label !== "Cases" && l.label !== "Analysis",
    );
  }
  return transformedLinks;
};

export const generatePagesRoutesForPersonalAccount = (
  routes: Array<{ path: string; element: any }>,
  routeType: "project" | "deal",
) => {
  return routes.map((route) => ({
    path: replacePagePathForPersonalAccount(route.path, routeType),
    element: route.element,
  }));
};

export const generateDealSubTabsForPersonalAccount = (
  existingTabs: Record<string, ITab[]>,
): Record<string, ITab[]> => {
  const clonedTabs = cloneDeep(existingTabs);

  const updatedPageTabs: any = {};

  Object.keys(clonedTabs).forEach((key) => {
    const typeBoundKey = key as keyof Record<string, ITab[]>;
    const pageTabs = clonedTabs[typeBoundKey];
    const updatedTabs = pageTabs.map((t) => {
      return {
        ...t,
        path: replacePagePathForPersonalAccount(t.path, "deal"),
        pathToMatch: replacePagePathForPersonalAccount(t.pathToMatch, "deal"),
      };
    });
    updatedPageTabs[typeBoundKey] = updatedTabs;
  });

  return updatedPageTabs as IDealOutputPageTabs;
};

const transformSummaryTableRows = (
  rows: ISummaryTable["rows"],
  headers: ISummaryTable["headers"],
  generatedRows: GridRowModel<GridValidRowModel>[],
  hierarchy: string[],
  nestedStep: number,
) => {
  rows.forEach((row) => {
    const generatedRow: GridRowModel<GridValidRowModel> = {
      id: uniqueId(),
    };

    // slice is to prevent duplicate columns in the table
    // First column is always the hierarchy column and the next is duplicated if not skipped
    row.cells.slice(headers.length ? 0 : 1).forEach((cell, idx) => {
      let headerLabel =
        headers.length === 0 ? `col_${idx}` : headers[idx].value;

      const headerLabelCharLen = headers[idx]?.meta_info?.max_char_length;
      if (headerLabelCharLen) {
        headerLabel = headerLabel.slice(0, headerLabelCharLen);
      }

      const startAdornment =
        cell.meta_info?.start_adornment ||
        headers[idx]?.meta_info?.start_adornment ||
        "";
      const endAdornment =
        cell.meta_info?.end_adornment ||
        headers[idx]?.meta_info?.end_adornment ||
        "";

      const formatedValue =
        cell.data_type === "number"
          ? numberWithDecimalsAndCommas(Number(cell.value))
          : cell.value;

      const value = startAdornment + formatedValue + endAdornment;

      generatedRow[headerLabel] = value;

      // hack to get cell hyperlink value
      generatedRow[String(headerLabel) + String(value) + "hyperLink"] =
        cell.meta_info?.hyperlink;
      // hack to make single cell bold
      generatedRow[String(headerLabel) + String(value) + "bold"] =
        cell.meta_info?.bold;
    });

    const startAdornment =
      row.cells[0].meta_info?.start_adornment ||
      headers[0]?.meta_info?.start_adornment ||
      "";
    const endAdornment =
      row.cells[0].meta_info?.end_adornment ||
      headers[0]?.meta_info?.end_adornment ||
      "";

    hierarchy = cloneDeep([
      ...hierarchy.slice(0, nestedStep),
      String(startAdornment + row.cells[0].value + endAdornment),
    ]);

    generatedRow["hierarchy"] = cloneDeep(hierarchy);

    generatedRows.push(generatedRow);

    if (row.components) {
      transformSummaryTableRows(
        row.components,
        headers,
        generatedRows,
        hierarchy,
        nestedStep + 1,
      );
    }
  });

  return generatedRows;
};

const commonSummaryTableColDef: Omit<GridColDef, "field"> = {
  flex: 1,
  sortable: false,
  align: "right",
  headerAlign: "right",
};

export const transformToSummaryTable = (
  table: ISummaryTable,
): { rows: GridRowsProp; columns: GridColDef[]; hideHeader: boolean } => {
  const clonedTable = cloneDeep(table);
  let hideHeader = false;

  let columns: GridColDef[] = clonedTable.headers.slice(1).map((header) => {
    const cellTextCharLen = header.meta_info?.max_char_length;

    const field = cellTextCharLen
      ? header.value.slice(0, cellTextCharLen)
      : header.value;

    return {
      ...commonSummaryTableColDef,
      field,
      bold: header.meta_info?.bold || false,
    };
  });

  // Below is a hack to get around the issue if there are no headers
  // If there are no headers, cells mapping to correct column is not possible
  if (clonedTable.headers.length === 0) {
    hideHeader = true;

    columns = clonedTable.rows[0].cells.slice(1).map((_, idx) => {
      return {
        ...commonSummaryTableColDef,
        field: `col_${idx}`,
      };
    });
  }

  const rows = transformSummaryTableRows(
    clonedTable.rows,
    clonedTable.headers,
    [],
    [],
    0,
  );

  return { columns, rows, hideHeader };
};
