import { DEAL_API_ROUTES, REPORT_API_ROUTES } from "../../constants";
import {
  AxiosHelper,
  addParamsToUrl,
  replaceParams,
} from "../../utils/helpers";
import {
  IReport,
  ReportPerspective,
  ReportType,
  IDealReportByStage,
  ICashReportChart,
  IPartnershipReportSplit,
  OutputReportPeriodicityType,
  ITaxEquityReportSummaryMaster,
  ISponsorEquityReportSummaryMaster,
  IDebtReportSummaryMaster,
  ReportTerm,
  IPartnershipReportSummaryMaster,
  ICapitalChart,
  IDealDebtBalanceChart,
  ITransferReportSummaryMaster,
  ITaxEquityChart,
  ISponsorCashEquityChart,
  ITaxEquityCashChart,
  ISponsorCashFlowChart,
  IValueComponentSummaryResponse,
  IDeveloperReportSummary,
  ISponsorEquityBOL_ReportSummary,
  IConstructionDebtSummary,
  IPortfolioCostScheduleReport,
  IPortfolioCostSummaryReport,
  IDealReport,
  OutputReportLeverage,
} from "../../interfaces";
import {
  getDealTimeSeriesReportUrl,
  getDownloadDealTimeSeriesReportUrl,
} from "../../utils/helpers/urls.helpers";

export const getDealTimeSeriesReport = async <Report = IDealReport>(
  dealUuid: string,
  reportPerspective: ReportPerspective,
  reportType: ReportType,
  reportTerm: ReportTerm,
  periodicity?: OutputReportPeriodicityType,
  reportLeverage?: OutputReportLeverage,
) => {
  const url = getDealTimeSeriesReportUrl(
    dealUuid,
    reportPerspective,
    reportType,
    reportTerm,
    periodicity,
    reportLeverage,
  );
  return await AxiosHelper.get<Report>(url);
};

export const downloadDealTimeSeriesReport = async (
  dealUuid: string,
  reportPerspective: ReportPerspective,
  reportType: ReportType,
  reportTerm: ReportTerm,
  periodicity?: OutputReportPeriodicityType,
  reportLeverage?: OutputReportLeverage,
) => {
  const url = getDownloadDealTimeSeriesReportUrl(
    dealUuid,
    reportPerspective,
    reportType,
    reportTerm,
    periodicity,
    reportLeverage,
  );
  return await AxiosHelper.downloadFile(url);
};

export const getDealReporting = async <Report = IReport>(
  dealUuid: string,
  reportPerspective: ReportPerspective,
  reportType: ReportType,
  reportTerm: ReportTerm,
  periodicity?: OutputReportPeriodicityType,
  reportingType?: string,
) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.get, {
    dealUuid,
    reportPerspective,
    reportType,
    periodicity,
    reportTerm,
    reportingType,
  });
  return await AxiosHelper.get<Report>(url);
};

export const getSponsorEquityBOL_ReportSummary = async (
  dealUuid: string,
  params: { force_unlevered: boolean },
) => {
  const { force_unlevered } = params;
  const url = addParamsToUrl(
    REPORT_API_ROUTES.deal.getSponsorEquityBOL_ReportSummary,
    { dealUuid },
    force_unlevered ? { force_unlevered } : {},
  );

  return await AxiosHelper.get<ISponsorEquityBOL_ReportSummary>(url);
};

export const getDeveloperReportSummary = async (dealUuid: string) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getDeveloperReportSummary, {
    dealUuid,
  });
  return await AxiosHelper.get<IDeveloperReportSummary>(url);
};

export const getTaxEquityReportSummary = async (dealUuid: string) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getTaxEquityReportSummary, {
    dealUuid,
  });
  return await AxiosHelper.get<ITaxEquityReportSummaryMaster>(url);
};

export const getSponsorEquityReportSummary = async (
  dealUuid: string,
  params: { force_unlevered: boolean },
) => {
  const { force_unlevered } = params;
  const url = addParamsToUrl(
    REPORT_API_ROUTES.deal.getSponsorEquityReportSummary,
    { dealUuid },
    force_unlevered ? { force_unlevered } : {},
  );
  return await AxiosHelper.get<ISponsorEquityReportSummaryMaster>(url);
};

export const downloadSponsorEquityReportSummary = async (
  dealUuid: string,
  params: { force_unlevered: boolean },
) => {
  const { force_unlevered } = params;
  const url = addParamsToUrl(
    REPORT_API_ROUTES.deal.getSponsorEquityReportSummary,
    { dealUuid },
    force_unlevered ? { force_unlevered } : {},
  );
  return await AxiosHelper.downloadFile(`${url}output=xlsx`);
};

export const getDebtReportSummary = async (dealUuid: string) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getDebtReportSummary, {
    dealUuid,
  });
  return await AxiosHelper.get<IDebtReportSummaryMaster>(url);
};

export const getTransferReportSummary = async (dealUuid: string) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getTransferReportSummary, {
    dealUuid,
  });
  return await AxiosHelper.get<ITransferReportSummaryMaster>(url);
};

export const getOutputPartnershipSummary = async (dealUuid: string) => {
  const url = replaceParams(
    REPORT_API_ROUTES.deal.getOutputPartnershipSummary,
    {
      dealUuid,
    },
  );
  return await AxiosHelper.get<IPartnershipReportSummaryMaster>(url);
};

export const getConstructionDebtSummary = async (dealUuid: string) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getConstructionDebtSummary, {
    dealUuid,
  });
  return await AxiosHelper.get<IConstructionDebtSummary>(url);
};

export const getDealOutputPortfolioCostSchedule = async (dealUuid: string) => {
  const url = replaceParams(
    REPORT_API_ROUTES.deal.getDealOutputPortfolioCostSchedule,
    {
      dealUuid,
    },
  );
  return await AxiosHelper.get<IPortfolioCostScheduleReport>(url);
};

export const getDealOutputPortfolioCostSummary = async (dealUuid: string) => {
  const url = replaceParams(
    REPORT_API_ROUTES.deal.getDealOutputPortfolioCostSummary,
    {
      dealUuid,
    },
  );
  return await AxiosHelper.get<IPortfolioCostSummaryReport>(url);
};

export const downloadPortfolioCostScheduleData = async (dealUuid: string) => {
  const url = replaceParams(
    REPORT_API_ROUTES.deal.downloadPortfolioCostScheduleData,
    {
      dealUuid,
    },
  );

  return await AxiosHelper.downloadFile(url);
};

export const getOutputPartnershipNetValueComponentChart = async (
  dealUuid: string,
) => {
  const url = replaceParams(
    REPORT_API_ROUTES.deal.getOutputPartnershipNetValueComponentChart,
    {
      dealUuid,
    },
  );
  return await AxiosHelper.get<IValueComponentSummaryResponse>(url);
};

export const getOutputPartnershipSplit = async (dealUuid: string) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getOutputPartnershipSplit, {
    dealUuid,
  });
  return await AxiosHelper.get<IPartnershipReportSplit>(url);
};

export const getCapitalChartData = async (dealUuid: string) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getCapitalChartData, {
    dealUuid,
  });
  return await AxiosHelper.get<ICapitalChart>(url);
};

export const getNonTransferCashChart = async (
  dealUuid: string,
  is_percent: string,
) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getNonTransferCashChart, {
    dealUuid,
    is_percent,
  });
  return await AxiosHelper.get<ICashReportChart>(url);
};

export const getTransferCashChart = async (
  dealUuid: string,
  is_percent: string,
) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getTransferCashChart, {
    dealUuid,
    is_percent,
  });
  return await AxiosHelper.get<ICashReportChart>(url);
};

export const getDealDebtServiceChart = async (
  dealUuid: string,
  is_percent: string,
) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getDealDebtServiceChart, {
    dealUuid,
    is_percent,
  });
  return await AxiosHelper.get<ICashReportChart>(url);
};

export const getDealSponsorCashEquityChart = async (
  dealUuid: string,
  is_percent: string,
) => {
  const url = replaceParams(
    REPORT_API_ROUTES.deal.getDealSponsorCashEquityChart,
    {
      dealUuid,
      is_percent,
    },
  );
  return await AxiosHelper.get<ISponsorCashEquityChart>(url);
};

export const getDealSponsorEquityCashChart = async (
  dealUuid: string,
  is_percent: string,
) => {
  const url = replaceParams(
    REPORT_API_ROUTES.deal.getDealSponsorEquityCashChart,
    {
      dealUuid,
      is_percent,
    },
  );
  return await AxiosHelper.get<ISponsorCashFlowChart>(url);
};

export const getDealTaxEquityChart = async (
  dealUuid: string,
  is_percent: string,
) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getDealTaxEquityChart, {
    dealUuid,
    is_percent,
  });
  return await AxiosHelper.get<ITaxEquityChart>(url);
};

export const getDealTaxEquityCashChart = async (
  dealUuid: string,
  is_percent: string,
) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getDealTaxEquityCashChart, {
    dealUuid,
    is_percent,
  });
  return await AxiosHelper.get<ITaxEquityCashChart>(url);
};

export const getDealDebtBalanceChart = async (
  dealUuid: string,
  is_percent: string,
) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getDealDebtBalanceChart, {
    dealUuid,
    is_percent,
  });
  return await AxiosHelper.get<IDealDebtBalanceChart>(url);
};

export const getDealPartnershipDepreciationChart = async (
  dealUuid: string,
  is_percent: string,
) => {
  const url = replaceParams(
    REPORT_API_ROUTES.deal.getDealPartnershipDepreciationChart,
    {
      dealUuid,
      is_percent,
    },
  );
  return await AxiosHelper.get<ICashReportChart>(url);
};

export const getDealNetOperatingIncomeChart = async (
  dealUuid: string,
  is_percent: string,
) => {
  const url = replaceParams(
    REPORT_API_ROUTES.deal.getDealNetOperatingIncomeChart,
    {
      dealUuid,
      is_percent,
    },
  );
  return await AxiosHelper.get<ICashReportChart>(url);
};

export const getDealOtherIncomeChart = async (
  dealUuid: string,
  is_percent: string,
) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getDealOtherIncomeChart, {
    dealUuid,
    is_percent,
  });
  return await AxiosHelper.get<ICashReportChart>(url);
};

export const downloadUserDealReport = async (
  dealUuid: string,
  reportPerspective?: ReportPerspective,
  reportType?: ReportType,
  reportTerm?: ReportTerm,
  periodicity?: OutputReportPeriodicityType,
) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getDownloadUserDealReport, {
    dealUuid,
    reportPerspective,
    reportType,
    periodicity,
    reportTerm,
  });

  return await AxiosHelper.downloadFile(url);
};

export const downloadDealReport = async (dealUuid: string) => {
  return await AxiosHelper.downloadFile(
    `reporting/deal/${dealUuid}/raw-download/`,
  );
};

export const downloadDealReportSet = async (dealUuid: string) => {
  return await AxiosHelper.downloadFile(
    `reporting/deal/${dealUuid}/download-reportset/`,
  );
};

export const downloadABCInputs = async (dealUuid: string) => {
  return await AxiosHelper.downloadFile(
    `accufy/deal/${dealUuid}/inputs-download/`,
  );
};

export const getDealReportingByStage = async () => {
  return await AxiosHelper.get<IDealReportByStage>(
    REPORT_API_ROUTES.deal.getByStage,
  );
};

export const downloadDealCostsReport = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.cost.summary.download, {
    dealUuid,
  });
  return await AxiosHelper.downloadFile(url);
};
