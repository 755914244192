import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { format } from "date-fns";
import { useDispatch } from "react-redux";

import useStyles from "./styles";
import UpsellView from "../../upsell-view";
import Button from "../../../components/button";
import ViewWrapper from "../../../components/view-wrapper";
import EditOutlinedIcon from "../../../components/logos-and-icons/edit-icon";
import CounterpartyFormModal from "../../../components/counterparty-form-modal";
import IconButton from "../../../components/icon-button";
import CounterpartyUserFormModal from "../../../components/counterparty-user-form-modal";
import GroupedTable, { IGroupedData } from "../../../components/grouped-table";
import ConditionalProtect from "../../../components/conditional-protect";
import PopoverMenu from "../../../components/popover-menu";
import { cn, trimString } from "../../../utils/helpers";
import { useAPI, useAppSelector } from "../../../utils/hooks";
import { COUNTERPARTY_TYPE } from "../../../constants";
import { setDeleteModalPropsAction } from "../../../utils/redux/slices";
import {
  ICounterparty,
  ICounterpartyForm,
  ICounterpartyFormErrors,
  ICounterpartyUserForm,
  ICounterpartyUser,
  ServerPaginatedResponse,
  ICounterpartyUserFormErrors,
} from "../../../interfaces";

interface IProps {
  getCounterparties: () => Promise<ServerPaginatedResponse<ICounterparty[]>>;
  createCounterparty: (counterparty: ICounterparty) => Promise<ICounterparty>;
  updateCounterparty: (
    id: number,
    counterparty: ICounterparty,
  ) => Promise<ICounterparty>;
  addCounterpartyUser: (
    form: ICounterpartyUserForm,
  ) => Promise<ICounterpartyUser>;
  deleteCounterpartyUser: (userId: number) => Promise<boolean>;
  deleteCounterparty: (id: number) => Promise<boolean>;
}

export default function CounterPartiesView({
  getCounterparties,
  createCounterparty,
  updateCounterparty,
  addCounterpartyUser,
  deleteCounterpartyUser,
  deleteCounterparty,
}: IProps): JSX.Element {
  const styles = useStyles();

  const dispatch = useDispatch();

  const [addCounterpartyForm, setAddCounterpartyForm] =
    React.useState<ICounterpartyForm>({
      name: "",
      type: "",
    });
  const [editCounterpartyForm, setEditCounterpartyForm] =
    React.useState<ICounterpartyForm>({
      name: "",
      type: "",
    });
  const [isAddCounterpartyModalOpen, setIsAddCounterpartyModalOpen] =
    React.useState(false);
  const [isEditCounterpartyModalOpen, setIsEditCounterpartyModalOpen] =
    React.useState(false);
  const [selectedCounterparty, setSelectedCounterparty] =
    React.useState<ICounterparty | null>(null);
  const [addCounterpartyUserForm, setAddCounterpartyUserForm] =
    React.useState<ICounterpartyUserForm>({
      counterparty: null,
      emails: [],
      email: null,
    });
  const [isAddCounterpartyUserModalOpen, setIsAddCounterpartyUserModalOpen] =
    React.useState(false);

  const handleOpenAddCounterpartyModal = () => {
    setIsAddCounterpartyModalOpen(true);
  };

  const handleCloseAddCounterpartyModal = () => {
    setIsAddCounterpartyModalOpen(false);
  };

  const { currentOrg } = useAppSelector((s) => s.org);

  const handleOpenEditCounterpartyModal = (uuid: string) => {
    setIsEditCounterpartyModalOpen(true);
    const counterparty = counterparties?.results.find((c) => c.uuid === uuid);
    counterparty && setEditCounterpartyForm(counterparty);
    counterparty && setSelectedCounterparty(counterparty);
  };

  const handleCloseEditCounterpartyModal = () => {
    setIsEditCounterpartyModalOpen(false);
    setSelectedCounterparty(null);
  };

  const handleCreateCounterparty = async (form: ICounterpartyForm) => {
    const counterparty = await createCounterpartyCallAPI(form);
    counterparty && getCounterpartiesCallAPI();
    counterparty &&
      setAddCounterpartyUserForm({
        counterparty: counterparty.uuid,
        emails: [],
        email: null,
      });
    counterparty && handleOpenAddCounterpartyUserModal(counterparty.uuid);
    return counterparty;
  };

  const handleUpdateCounterparty = async (form: ICounterpartyForm) => {
    const counterparty = await updateCounterpartyCallAPI(
      selectedCounterparty?.uuid,
      form,
    );
    counterparty && getCounterpartiesCallAPI();
    return counterparty;
  };

  const handleOpenAddCounterpartyUserModal = (...args: (string | number)[]) => {
    const uuid = args[0] as string;

    setIsAddCounterpartyUserModalOpen(true);
    const counterparty = counterparties?.results.find((c) => c.uuid === uuid);
    counterparty && setSelectedCounterparty(counterparty);
    counterparty &&
      setAddCounterpartyUserForm({
        counterparty: counterparty.uuid,
        emails: [],
        email: null,
      });
  };

  const handleCloseAddCounterpartyUserModal = () => {
    setIsAddCounterpartyUserModalOpen(false);
    setSelectedCounterparty(null);
  };

  const handleCreateCounterpartyUser = async (form: ICounterpartyUserForm) => {
    delete form.email;
    const counterpartyUser = await addCounterpartyUserCallAPI(form);
    counterpartyUser && getCounterpartiesCallAPI();
    return counterpartyUser;
  };

  const {
    callAPI: getCounterpartiesCallAPI,
    loading: counterpartiesLoading,
    errored: counterpartiesErrored,
    response: counterparties,
  } = useAPI(getCounterparties, {
    initialLoading: currentOrg?.is_feature_external_sharing_enabled,
  });

  const {
    callAPI: createCounterpartyCallAPI,
    loading: createCounterpartyLoading,
    fieldErrors: createCounterpartyFieldErrors,
    setFieldErrors: createCounterpartySetFieldErrors,
  } = useAPI<ICounterparty, ICounterpartyFormErrors>(createCounterparty);

  const {
    callAPI: updateCounterpartyCallAPI,
    loading: updateCounterpartyLoading,
    fieldErrors: updateCounterpartyFormErrors,
    setFieldErrors: setUpdateCounterpartyFormErrors,
  } = useAPI<ICounterparty, ICounterpartyFormErrors>(updateCounterparty);

  const {
    callAPI: addCounterpartyUserCallAPI,
    loading: addCounterpartyUserLoading,
    fieldErrors: addCounterpartyUserFieldErrors,
    setFieldErrors: addCounterpartyUserSetFieldErrors,
  } = useAPI<ICounterpartyUser, ICounterpartyUserFormErrors>(
    addCounterpartyUser,
  );

  const {
    callAPI: deleteCounterpartyUserCallAPI,
    loading: deleteCounterpartyUserLoading,
  } = useAPI(deleteCounterpartyUser);

  const {
    callAPI: deleteCounterpartyCallAPI,
    loading: deleteCounterpartyLoading,
  } = useAPI(deleteCounterparty);

  const onDeleteCounterpartyUser = async (userId: number) => {
    const deleted = await deleteCounterpartyUserCallAPI(userId);
    if (deleted) {
      getCounterpartiesCallAPI();
    }
  };

  function transformToGroupedData(
    counterparties: ICounterparty[],
  ): IGroupedData<ICounterpartyUser>[] {
    return counterparties.map((counterparty) => ({
      id: counterparty.uuid,
      name: counterparty.name,
      type: counterparty.type,
      items: counterparty.counterparty_user_list.map(
        (user: ICounterpartyUser) => ({
          id: user.uuid as string,
          uuid: user.uuid as string,
          email: user.email,
          created: user.created,
        }),
      ),
    }));
  }

  const handleDeleteCounterpartyUser = (...args: (string | number)[]) => {
    const userId = args[0] as number;
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Counterparty User",
        description: `Are you sure you want to delete?`,
        onConfirm: () => onDeleteCounterpartyUser(userId),
        loading: deleteCounterpartyUserLoading,
      }),
    );
  };

  React.useEffect(() => {
    if (currentOrg?.is_feature_external_sharing_enabled) {
      getCounterpartiesCallAPI();
    }
  }, [currentOrg]);

  const counterPartyData = React.useMemo(
    () => transformToGroupedData(counterparties?.results || []),
    [counterparties?.results],
  );

  const onDeleteCounterparty = async (counterpartyUuid: string) => {
    const deleted = await deleteCounterpartyCallAPI(counterpartyUuid);
    if (deleted) {
      getCounterpartiesCallAPI();
    }
  };

  const handleDeleteCounterparty = (counterpartyUuid: string) => {
    if (counterpartyUuid === undefined) {
      return;
    }

    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Counterparty",
        description:
          "Are you sure you want to delete Counterparty? All their users will be deleted and they will lose access to deals/cases they have access to (if any), via external sharing",
        onConfirm: () => onDeleteCounterparty(counterpartyUuid),
        loading: deleteCounterpartyLoading,
      }),
    );
  };

  if (!currentOrg?.is_feature_external_sharing_enabled) {
    return <UpsellView />;
  }

  return (
    <>
      <ViewWrapper
        loading={counterpartiesLoading}
        error={counterpartiesErrored}
      >
        {counterparties?.results.length === 0 ? (
          <Box
            className={cn(
              "flex justify-center items-center h-[calc(100vh_-_260px)]",
            )}
          >
            <Box className={cn("flex flex-col items-center")}>
              <ConditionalProtect type="counterparty">
                <Button
                  startIcon={<AddIcon />}
                  label="Add Counterparty"
                  onClick={handleOpenAddCounterpartyModal}
                  btnType="primary"
                  className={cn("!mb-8 h-[66px] !px-6")}
                />
              </ConditionalProtect>
              <Typography
                variant="body1"
                className={styles.classes.createInfo}
                data-pw="empty-project-valuation-summary-message"
              >
                Counterparty does not exist, click on Add Add Counterparty to
                add one.
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box className={cn("!mt-4 mb-14")}>
            <Box className={cn("flex justify-end mb-4")}>
              <ConditionalProtect type="counterparty">
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleOpenAddCounterpartyModal}
                  btnType="primary"
                  label="Add Counterparty"
                />
              </ConditionalProtect>
            </Box>

            <Paper>
              <GroupedTable<IGroupedData<ICounterpartyUser>, ICounterpartyUser>
                data={counterPartyData}
                onAction={handleOpenAddCounterpartyUserModal}
                onDelete={handleDeleteCounterpartyUser}
                actionLabel="+ Add"
                userPermission="counterparty"
                hideActionColumn
                renderGroupHeader={(group, toggleRow, isOpen) => (
                  <Box
                    className={cn("flex justify-between items-center gap-4")}
                  >
                    <Box className={cn("flex items-center gap-2")}>
                      <IconButton size="small" onClick={toggleRow}>
                        {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </IconButton>
                      <Typography>{trimString(group.name, 40)}</Typography>
                      <Chip
                        label={
                          COUNTERPARTY_TYPE[
                            group.type as keyof typeof COUNTERPARTY_TYPE
                          ]
                        }
                      />
                      <ConditionalProtect type="counterparty">
                        <IconButton
                          onClick={() =>
                            handleOpenEditCounterpartyModal(group.id as string)
                          }
                        >
                          <EditOutlinedIcon fillColor="#5F51F4" />
                        </IconButton>
                        <IconButton
                          className="!text-danger !-ml-4"
                          size="small"
                          onClick={() =>
                            handleDeleteCounterparty(group.id as string)
                          }
                        >
                          <DeleteOutline />
                        </IconButton>
                      </ConditionalProtect>
                    </Box>
                  </Box>
                )}
                renderColumns={() => [
                  <TableCell key="email">Email</TableCell>,
                  <TableCell key="created">Created</TableCell>,
                  <TableCell align="right" key="actions">
                    Actions
                  </TableCell>,
                ]}
                renderItem={(item) => (
                  <>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>
                      {format(new Date(item.created), "MM/dd/yyyy")}
                    </TableCell>
                    <TableCell align="right">
                      <PopoverMenu
                        uniqueId={item.uuid}
                        canOpenUpgrade
                        items={[
                          {
                            label: "Delete",
                            onClick: () => {
                              handleDeleteCounterpartyUser(item.uuid);
                            },
                          },
                        ]}
                      />
                    </TableCell>
                  </>
                )}
              />
            </Paper>
          </Box>
        )}
      </ViewWrapper>

      <CounterpartyFormModal
        open={isAddCounterpartyModalOpen}
        headerLabel="Add Counterparty"
        loading={createCounterpartyLoading}
        form={addCounterpartyForm}
        setForm={setAddCounterpartyForm}
        formErrors={createCounterpartyFieldErrors}
        setFormErrors={createCounterpartySetFieldErrors}
        onClose={handleCloseAddCounterpartyModal}
        onConfirm={handleCreateCounterparty}
      />

      <CounterpartyFormModal
        open={isEditCounterpartyModalOpen}
        headerLabel="Edit Deal Counterparty"
        loading={updateCounterpartyLoading}
        form={editCounterpartyForm}
        setForm={setEditCounterpartyForm}
        formErrors={updateCounterpartyFormErrors}
        setFormErrors={setUpdateCounterpartyFormErrors}
        onClose={handleCloseEditCounterpartyModal}
        onConfirm={handleUpdateCounterparty}
      />

      <CounterpartyUserFormModal
        open={isAddCounterpartyUserModalOpen}
        loading={addCounterpartyUserLoading}
        headerLabel="Add user emails"
        form={addCounterpartyUserForm}
        setForm={setAddCounterpartyUserForm}
        formErrors={addCounterpartyUserFieldErrors}
        setFormErrors={addCounterpartyUserSetFieldErrors}
        onConfirm={handleCreateCounterpartyUser}
        onClose={handleCloseAddCounterpartyUserModal}
        hideFields={["email"]}
      />
    </>
  );
}
