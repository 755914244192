import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { formatDistanceToNow } from "date-fns";
import { useOrganization } from "@clerk/clerk-react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Chip from "../general/Chip";
import useStyles from "./styles";
import { useAppSelector, useLocalStorage } from "../../utils/hooks";
import {
  cn,
  getStatusChipFillColor,
  sortArrayOfObjects,
  trimString,
} from "../../utils/helpers";
import {
  DEAL_STATUS,
  PROJECT_STAGES,
  PROJECT_TAX_CREDIT_TYPE,
  PROJECT_ENERGY_TYPES,
  US_STATES,
} from "../../constants";
import {
  ITableSort,
  ITableColumn,
  SetStateAction,
  IProjectTableRow,
  IPersonalAccountNavBackButtonId,
} from "../../interfaces";

interface IProps {
  columns: ITableColumn[];
  rows: IProjectTableRow[];
  sortTable: ITableSort;
  setSortTable: SetStateAction<ITableSort>;
  openProjectInNewTab?: boolean;
  heightOptions?: {
    isFilterOpen?: boolean;
    fixedHeight?: boolean;
  };
  isFiltersApplied?: boolean;
  showCapacityDc?: boolean;
  isSearchApplied?: boolean;
  totalProjects?: number;
}

export default function ProjectsTable({
  columns,
  rows,
  sortTable,
  setSortTable,
  openProjectInNewTab,
  heightOptions,
  isFiltersApplied,
  showCapacityDc,
  isSearchApplied,
  totalProjects,
}: IProps): JSX.Element {
  const styles = useStyles({ heightOptions });

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { dealUuid } = useParams();

  const { organization } = useOrganization();

  const { ctrlPressed } = useAppSelector((s) => s.common);

  const [personalAccountNavBackButtonIds, setPersonalAccountNavBackButtonIds] =
    useLocalStorage<IPersonalAccountNavBackButtonId[]>(
      "personal-account-nav-back-btn",
      [],
    );

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);

  const sortRows = (orderBy: string) => {
    if (orderBy === sortTable.orderBy) {
      setSortTable({
        orderBy,
        order: sortTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const goToProject = (projectUuid: string) => {
    let pageURL = `/project/${projectUuid}/general`;

    if (!organization) {
      pageURL = organization ? pageURL : `/personal${pageURL}`;

      const collaborationObj = personalAccountNavBackButtonIds.find((d) =>
        d.deals.find((deal) => deal.uuid === dealUuid),
      );

      if (collaborationObj) {
        const idsInDealObj = collaborationObj.deals.find(
          (d) => d.uuid === dealUuid,
        );

        if (idsInDealObj && !idsInDealObj.projectUuids.includes(projectUuid)) {
          idsInDealObj.projectUuids.push(projectUuid);

          const filteredCollabIds = personalAccountNavBackButtonIds.filter(
            (ids) => ids.collaborationId !== collaborationObj.collaborationId,
          );

          const filteredDealIds = collaborationObj.deals.filter(
            (deal) => deal.uuid !== idsInDealObj.uuid,
          );

          setPersonalAccountNavBackButtonIds([
            ...filteredCollabIds,
            {
              collaborationId: collaborationObj.collaborationId,
              deals: [...filteredDealIds, idsInDealObj],
            },
          ]);
        }
      }
    }

    if (openProjectInNewTab || ctrlPressed) {
      window.open(pageURL, "_blank");
      return;
    }
    navigate(pageURL);
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () =>
      sortArrayOfObjects(rows, sortTable?.orderBy, sortTable?.order).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [rows, sortTable, page, rowsPerPage, searchParams],
  );

  return (
    <Paper
      sx={{ width: "100%", overflow: "hidden", borderTop: "1px solid #E0E0E0" }}
    >
      <TableContainer classes={{ root: styles.classes.tableContainer }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={styles.classes.header}>
            <TableRow>
              {columns.map((column, idx) => {
                return (
                  <TableCell
                    key={idx}
                    align={column.align as "left"}
                    style={{ minWidth: column.minWidth }}
                  >
                    <TableSortLabel
                      disabled={column.id === "action"}
                      active={sortTable.orderBy === column.id}
                      direction={
                        sortTable.orderBy === column.id
                          ? sortTable.order
                          : "asc"
                      }
                      onClick={() => sortRows(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={columns.length}>
                  {isSearchApplied
                    ? "No projects found matching your search term"
                    : isFiltersApplied
                      ? "No projects match your selected filters. Try adjusting the filters or add a new project"
                      : totalProjects === 0
                        ? "There are no Projects in place currently, please add one."
                        : "No projects found for the selected status"}
                </TableCell>
              </TableRow>
            )}

            {visibleRows.map((project, idx) => {
              return (
                <TableRow
                  hover
                  key={idx}
                  tabIndex={-1}
                  className={cn("cursor-pointer")}
                  onClick={() => goToProject(project.uuid)}
                  data-pw={`project-table-row-${idx + 1}`}
                >
                  <TableCell align="left" title={project.name}>
                    {trimString(project.name, 40)}
                  </TableCell>
                  <TableCell align="left">
                    {trimString(project.sponsor_name, 40)}
                  </TableCell>
                  <TableCell align="left">
                    {PROJECT_ENERGY_TYPES[project.energy_type]}
                  </TableCell>
                  <TableCell align="left">{US_STATES[project.state]}</TableCell>
                  <TableCell align="left">{project.capacity_ac} MW</TableCell>
                  {showCapacityDc && (
                    <TableCell align="left">{project.capacity_dc} MW</TableCell>
                  )}
                  <TableCell align="left">
                    {project.stage}: {PROJECT_STAGES[project.sub_stage]}
                  </TableCell>
                  <TableCell align="left">{project.cod}</TableCell>
                  <TableCell align="left">
                    {
                      PROJECT_TAX_CREDIT_TYPE[
                        project.tax_credit_type as keyof typeof PROJECT_TAX_CREDIT_TYPE
                      ]
                    }
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      label={DEAL_STATUS[project.status]}
                      color={getStatusChipFillColor(project.status).color}
                      filledBackgroundColor={
                        getStatusChipFillColor(project.status).backgroundColor
                      }
                      variant="filled"
                    />
                  </TableCell>
                  <TableCell align="left">
                    {formatDistanceToNow(new Date(project.modified), {
                      addSuffix: true,
                    })}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={rows.length}
        className={cn("bg-table-gray")}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
