import React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { isNumber } from "lodash";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";
import { useNavigate, useParams } from "react-router-dom";

import useStyle from "./styles";
import Logs from "../../../../../components/logs";
import Button from "../../../../../components/button";
import LogsWrapper from "../../../../../components/logs-wrapper";
import ViewWrapper from "../../../../../components/view-wrapper";
import DetailsCard from "../../../../../components/details-card";
import DealFeeFormModal from "../../../../../components/deal-fee-form-modal";
import ConditionalProtect from "../../../../../components/conditional-protect";
import LogsButton from "../../../../../components/logs-button";
import { setDeleteModalPropsAction } from "../../../../../utils/redux/slices";
import { useLogs, useDrawer, useAPI } from "../../../../../utils/hooks";
import {
  cn,
  numberToUSD,
  replaceCaseIdAttachedDealPagePath,
  trimString,
} from "../../../../../utils/helpers";
import {
  DEAL_FEE_TYPES,
  DEAL_FEE_ALLOCATION_METHODS,
  DEAL_FEE_COST_WEIGHTING_METHODS,
  DEAL_FEE_FORM_DEFAULT_STATE,
  DEAL_FEE_INPUT_TYPES,
  DEAL_FEE_SPEND_METHODS,
  DEAL_FEE_MILESTONES,
} from "../../../../../constants";
import {
  IDealFee,
  IDealFeeForm,
  IDealFeeFormErrors,
  ILogsConfiguration,
} from "../../../../../interfaces";

interface IProps {
  getDealFeeDetails: (dealUuid: string, feeId: number) => Promise<IDealFee>;
  updateDealFee: (
    dealUuid: string,
    feeId: number,
    form: IDealFeeForm,
  ) => Promise<IDealFee>;
  deleteDealFee: (dealUuid: string, feeId: number) => Promise<boolean>;
}

export default function DealFeeDetailsView({
  getDealFeeDetails,
  updateDealFee,
  deleteDealFee,
}: IProps): JSX.Element {
  const styles = useStyle();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { dealUuid, caseDealUuid, dealFeeId } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [dealFee, setDealFee] = React.useState<IDealFee>();
  React.useState<boolean>(false);
  const [updateDealFeeModalOpen, setUpdateDealFeeModalOpen] =
    React.useState<boolean>(false);
  const [form, setForm] = React.useState<IDealFeeForm>(
    DEAL_FEE_FORM_DEFAULT_STATE,
  );

  React.useEffect(() => {
    getDealFeeDetailsCallAPI(dealIdToUse, Number(dealFeeId)).then(
      (res) => res && setDealFee(res),
    );
  }, [dealIdToUse, dealFeeId]);

  const {
    callAPI: getDealFeeDetailsCallAPI,
    errored: getFeesFailed,
    loading: loadingFees,
  } = useAPI(
    (dealUuid: string, dealFeeId: number) =>
      getDealFeeDetails(dealUuid, dealFeeId),
    {
      initialLoading: true,
    },
  );

  const {
    callAPI: updateDealFeeCallAPI,
    fieldErrors: updateDealFeeFormErrors,
    setFieldErrors: setUpdateDealFeeFormErrors,
    loading: updateDealFeeLoading,
  } = useAPI<IDealFee, IDealFeeFormErrors>(
    (dealUuid: string, feeId: number, form: IDealFeeForm) =>
      updateDealFee(dealUuid, feeId, form),
  );

  const { callAPI: deleteDealFeeCallAPI } = useAPI(
    (dealUuid, feeId) => deleteDealFee(dealUuid, feeId),
    {
      setConfirmModalLoading: true,
    },
  );

  const handleOnUpdateDealFee = async (form: IDealFeeForm) => {
    const fee = await updateDealFeeCallAPI(
      caseDealUuid,
      Number(dealFee?.id),
      form,
    );
    fee && setDealFee(fee);
    return fee;
  };

  const handleOpenUpdateDealFeeModal = () => {
    if (dealFee) {
      const {
        name,
        fee_type,
        amount,
        allocation_method,
        amortization_term,
        input_type,
        spend_method,
        milestone,
        fee_multiplier,
        cost_weighting_method,
      } = dealFee;
      setForm({
        fee_type,
        name,
        amount,
        allocation_method,
        input_type,
        spend_method,
        milestone,
        fee_multiplier,
        amortization_term,
        cost_weighting_method,
      });
      setUpdateDealFeeModalOpen(true);
    }
  };

  const handleDeleteDealFee = async () => {
    const deletedFee = await deleteDealFeeCallAPI(caseDealUuid, dealFeeId);
    deletedFee && goBackToDealFees();
  };

  const handleOnDeleteClick = async () => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Fee",
        description: "Are you sure you want to delete this fee?",
        onConfirm: handleDeleteDealFee,
      }),
    );
  };

  const handleCloseUpdateDealFeeModal = () => {
    setUpdateDealFeeModalOpen(false);
  };

  const isOnCase = React.useMemo(() => {
    return dealUuid !== caseDealUuid;
  }, [dealUuid, caseDealUuid]);

  const dealFeeLogConfiguration: ILogsConfiguration = {
    id: Number(dealFeeId),
    type: "dealfee",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(dealFeeLogConfiguration.type, dealFeeLogConfiguration.id);
  };

  const goBackToDealFees = () => {
    let path = `/deal/${dealUuid}/case-deal/${caseDealUuid}/sizing/deal-fee`;

    if (!organization) {
      path = replaceCaseIdAttachedDealPagePath(path);
    }

    navigate(path);
  };

  return (
    <>
      <ViewWrapper loading={loadingFees} error={getFeesFailed}>
        <Box className={cn("flex justify-between gap-4 mb-4")}>
          <IconButton
            classes={{ root: styles.classes.iconButton }}
            onClick={goBackToDealFees}
          >
            <ArrowBackIcon />
          </IconButton>
          <Box className={cn("flex gap-4")}>
            <LogsButton onClick={handleOnOpenLogs} />

            <ConditionalProtect type="deal">
              {!isOnCase && (
                <>
                  <Button
                    canOpenUpgrade
                    startIcon={<EditIcon />}
                    label="Edit"
                    btnType="primary"
                    onClick={handleOpenUpdateDealFeeModal}
                  />
                  <Button
                    canOpenUpgrade
                    label="Delete"
                    btnType="danger"
                    onClick={handleOnDeleteClick}
                  />
                </>
              )}
            </ConditionalProtect>
          </Box>
        </Box>

        <Box className={cn("grid md:grid-cols-2 gap-4")}>
          {dealFee && (
            <DetailsCard
              heading="Fee Details"
              dataPw="fee-details"
              autoHeight
              sections={[
                {
                  fields: [
                    {
                      label: "Name",
                      value: {
                        text: trimString(dealFee.name, 40) || "N/A",
                      },
                    },
                    {
                      label: "Type",
                      value: {
                        text: DEAL_FEE_TYPES[dealFee.fee_type],
                      },
                    },
                    {
                      label: "Allocation Method",
                      value: {
                        text: DEAL_FEE_ALLOCATION_METHODS[
                          dealFee.allocation_method
                        ],
                      },
                    },
                    ...(dealFee.allocation_method == "DFA"
                      ? [
                          {
                            label: "Amortization Term",
                            value: {
                              text: isNumber(dealFee.amortization_term)
                                ? dealFee.amortization_term + "Yr"
                                : "N/A",
                            },
                          },
                        ]
                      : []),
                    ...(dealFee.allocation_method == "DAC"
                      ? [
                          {
                            label: "Cost Weighting Method",
                            value: {
                              text:
                                DEAL_FEE_COST_WEIGHTING_METHODS[
                                  dealFee.cost_weighting_method
                                ] ?? "N/A",
                            },
                          },
                        ]
                      : []),
                    {
                      label: "Input Type",
                      value: {
                        text: DEAL_FEE_INPUT_TYPES[dealFee.input_type],
                      },
                    },
                    {
                      label: "Spend Method",
                      value: {
                        text: DEAL_FEE_SPEND_METHODS[dealFee.spend_method],
                      },
                    },
                    ...(dealFee.spend_method == "MD"
                      ? [
                          {
                            label: "Milestone Date",
                            value: {
                              text:
                                DEAL_FEE_MILESTONES[dealFee.milestone] ?? "N/A",
                            },
                          },
                        ]
                      : []),
                    {
                      label: "Fee",
                      value: {
                        text: numberToUSD.format(dealFee.amount ?? 0),
                      },
                    },
                    {
                      label: "Fee Multiplier",
                      value: {
                        text: dealFee.fee_multiplier.toFixed(2) + "%",
                      },
                    },
                  ],
                },
              ]}
            />
          )}
        </Box>
      </ViewWrapper>

      <DealFeeFormModal
        open={updateDealFeeModalOpen}
        loading={updateDealFeeLoading}
        headerLabel="Update Deal Fee"
        formErrors={updateDealFeeFormErrors}
        setFormErrors={setUpdateDealFeeFormErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseUpdateDealFeeModal}
        onConfirm={handleOnUpdateDealFee}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={dealFeeLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={dealFeeLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
