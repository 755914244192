import React from "react";
import Box from "@mui/material/Box";

import Modal from "../modal";
import TextInput from "../text-input";
import AutocompleteField from "../autocomplete-field";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  PROJECT_TYPE_OPTIONS,
  PROJECT_STAGE_OPTIONS,
  US_STATES_OPTIONS,
  MARKETS_OPTIONS,
  PROJECT_FORM_DEFAULT_STATE,
  DEAL_STATUS_OPTIONS,
  CREATION_DEAL_STATUS_OPTIONS,
  FORM_TYPES,
} from "../../constants";
import {
  IProjectForm,
  IProjectFormErrors,
  SetStateAction,
  ISelectOption,
  IAddUpdateProjectResponse,
  ICounty,
} from "../../interfaces";
import Select from "../select-input";
import { getCounties } from "../../apis/common";
import { useAPI } from "../../utils/hooks";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  formErrors?: IProjectFormErrors;
  setFormErrors: SetStateAction<IProjectFormErrors | undefined>;
  form: IProjectForm;
  setForm: SetStateAction<IProjectForm>;
  onClose: () => void;
  onConfirm: (
    form: IProjectForm,
  ) => Promise<IAddUpdateProjectResponse | undefined>;
  hide?: (keyof IProjectForm)[];
  formType?: string;
  countyOptions: ISelectOption[];
  onStateChange?: (value: string) => void;
}

export default function ProjectFormModal({
  open,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
  countyOptions,
  onStateChange,
  hide = [],
  formType = FORM_TYPES.UPDATE,
}: IProps): JSX.Element {
  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSingleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | null,
    name: string,
  ) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: value?.value,
      };
    });
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    const project = await onConfirm(form);
    project && handleOnClose();
  };

  const handleResetForm = () => {
    setForm(PROJECT_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  const handleSelectInputChangeChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  return (
    <Modal
      maxWidth="xs"
      open={open}
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <Box>
        {!hide.includes("name") && (
          <TextInput
            required
            label="Project Name"
            name="name"
            value={form.name}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.name)}
            helperText={formErrors?.name}
            disabled={loading}
            autoFocus
          />
        )}
        {!hide.includes("sponsor_name") && (
          <TextInput
            required
            label="Sponsor Name"
            name="sponsor_name"
            value={form.sponsor_name}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.sponsor_name)}
            helperText={formErrors?.sponsor_name}
            disabled={loading}
          />
        )}
        {!hide.includes("energy_type") && (
          <AutocompleteField
            label="Project Type"
            name="energy_type"
            onChange={handleSingleAutoCompleteChange}
            options={PROJECT_TYPE_OPTIONS}
            value={String(form.energy_type)}
            helperText={formErrors?.energy_type}
            onFocus={() => {
              clearSelectErrorOnFocus("energy_type");
            }}
            disabled={loading}
          />
        )}
        {!hide.includes("state") && (
          <AutocompleteField
            label="Project State"
            name="state"
            onChange={(e, value) => {
              handleSingleAutoCompleteChange(e, value, "state");
              onStateChange?.(value?.value);
            }}
            options={US_STATES_OPTIONS}
            value={String(form.state)}
            helperText={formErrors?.state}
            onFocus={() => {
              clearSelectErrorOnFocus("state");
            }}
            disabled={loading}
          />
        )}
        {!hide.includes("county") && (
          <AutocompleteField
            label="Project County (optional)"
            name="county"
            onChange={(e, value) => {
              handleSingleAutoCompleteChange(e, value, "county");
            }}
            onInputChange={(e, value) => {
              if (value === "") {
                setForm((prev) => ({
                  ...prev,
                  county: "",
                }));
              }
            }}
            options={countyOptions}
            value={String(form.county)}
            helperText={formErrors?.county}
            onFocus={() => {
              clearSelectErrorOnFocus("county");
            }}
            disabled={!form.state}
          />
        )}
        {!hide.includes("power_market") && (
          <AutocompleteField
            label="Power Market"
            name="power_market"
            onChange={handleSingleAutoCompleteChange}
            options={MARKETS_OPTIONS}
            value={String(form.power_market)}
            helperText={formErrors?.power_market}
            onFocus={() => {
              clearSelectErrorOnFocus("power_market");
            }}
            disabled={loading}
          />
        )}
        {!hide.includes("sub_stage") && (
          <AutocompleteField
            label="Project Stage"
            name="sub_stage"
            onChange={handleSingleAutoCompleteChange}
            options={PROJECT_STAGE_OPTIONS}
            value={String(form.sub_stage)}
            helperText={formErrors?.sub_stage}
            onFocus={() => {
              clearSelectErrorOnFocus("sub_stage");
            }}
            disabled={loading}
          />
        )}
        <Select
          required
          label="Status"
          selected={form.status}
          items={
            formType === FORM_TYPES.UPDATE
              ? DEAL_STATUS_OPTIONS
              : CREATION_DEAL_STATUS_OPTIONS
          }
          onFocus={() => clearSelectErrorOnFocus("status")}
          onChange={(e) => handleSelectInputChangeChange(e, "status")}
          error={Boolean(formErrors?.status)}
          helperText={formErrors?.status}
          disabled={loading}
          fullWidth={true}
        />
      </Box>
    </Modal>
  );
}
