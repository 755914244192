import React from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import Alert from "@mui/material/Alert";
import ShareIcon from "@mui/icons-material/Share";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import useStyles from "./styles";
import Button from "../../../components/button";
import ViewWrapper from "../../../components/view-wrapper";
import ConditionalProtect from "../../../components/conditional-protect";
import CollaborationCounterpartyFormModal from "../../../components/collaboration-counterparty-form-modal";
import CollaborationUserManageFormModal from "../../../components/collaboration-user-manage-form-modal";
import GroupedTable, { IGroupedData } from "../../../components/grouped-table";
import {
  setConfirmModalPropsAction,
  setDeleteModalPropsAction,
  resetConfirmModalPropsAction,
} from "../../../utils/redux/slices";
import { useAPI, useAppSelector } from "../../../utils/hooks";
import { cn, trimString } from "../../../utils/helpers";
import { sendDealShareEmail } from "../../../apis/collaboration/base";
import { COUNTERPARTY_TYPE } from "../../../constants";
import {
  ICounterparty,
  ICounterpartyUser,
  ISelectOption,
  ServerPaginatedResponse,
  ICollaborationCounterpartyForm,
  ICollaborationCounterparty,
  ICollaborationCounterpartyFormErrors,
} from "../../../interfaces";

interface IProps {
  addDealCounterparty: (
    collaborationUuid: string,
    counterpartyForm: ICollaborationCounterpartyForm,
  ) => Promise<ICollaborationCounterparty>;
  getDealCounterparties: (
    collaborationUuid: string,
  ) => Promise<ServerPaginatedResponse<ICollaborationCounterparty[]>>;
  getCounterparties: () => Promise<ServerPaginatedResponse<ICounterparty[]>>;
  getSingleCounterparty: (id: number) => Promise<ICounterparty>;
  updateDealCounterparty: (
    collaborationUuid: string,
    id: number,
    form: ICollaborationCounterpartyForm,
  ) => Promise<ICollaborationCounterparty>;
  deleteCollaborationCounterparty: (
    collaborationUuid: string,
    id: number,
  ) => Promise<boolean>;
}

export default function CollaborationAccessView({
  addDealCounterparty,
  getDealCounterparties,
  getCounterparties,
  updateDealCounterparty,
  getSingleCounterparty,
  deleteCollaborationCounterparty,
}: IProps): JSX.Element {
  const styles = useStyles();

  const dispatch = useDispatch();

  const { collaborationUuid } = useParams();
  const { currentCollaboration } = useAppSelector((s) => s.collaboration);

  const [counterpartyForm, setCounterpartyForm] =
    React.useState<ICollaborationCounterpartyForm>({
      counterparty: null,
      counterparty_users: [],
    });

  const [counterPartyInvitationForm, setCounterPartyInvitationForm] =
    React.useState<ICollaborationCounterpartyForm>({
      counterparty: null,
      counterparty_users: [],
    });

  const [isAddCounterpartyModalOpen, setIsAddCounterpartyModalOpen] =
    React.useState(false);
  const [isAddManageUserModalOpen, setIsAddManageUserModalOpen] =
    React.useState(false);
  const [isEditManageUserModalOpen, setIsEditManageUserModalOpen] =
    React.useState(false);
  const [isSendInvitationModalOpen, setIsSendInvitationModalOpen] =
    React.useState(false);
  const [isEditCounterpartyModalOpen, setIsEditCounterpartyModalOpen] =
    React.useState(false);
  const [collaborationCounterparties, setCollaborationCounterparties] =
    React.useState<ICollaborationCounterparty[]>([]);

  const [userOptions, setUserOptions] = React.useState<ISelectOption[]>([]);

  const handleAddCounterpartyModalOpen = async () => {
    await getCounterpartiesCallAPI();
    setIsAddCounterpartyModalOpen(true);
  };

  const handleAddCounterpartyModalClose = () => {
    setIsAddCounterpartyModalOpen(false);
  };

  const handleAddManageUserModalClose = () => {
    setIsAddManageUserModalOpen(false);
  };

  const handleEditManageUserModalOpen = async (
    counterparty: ICollaborationCounterparty,
  ) => {
    // Getting all the list of the users in the selected counterparty
    const singleCounterparty = await getSingleCounterpartyCallAPI(
      counterparty.counterparty_uuid,
    );

    // Selecting only those users who are in the selected counterparty
    setCounterpartyForm({
      counterparty: counterparty.id.toString(),
      counterparty_users:
        counterparty.counterparty_user_list.map(
          (user: { uuid: string }) => user.uuid,
        ) || [],
    });

    const users = singleCounterparty?.counterparty_user_list.map(
      (u: { uuid: string; email: string }) => ({
        label: u.email,
        value: u.uuid,
      }),
    );
    setUserOptions(users || []);

    setTimeout(() => {
      setIsEditManageUserModalOpen(true);
    }, 200);
  };

  const handleSendInvitationModalOpen = async (
    counterpartyUsers: ICounterpartyUser[],
    collaborationCounterpartyId: number,
  ) => {
    // Selecting only those users who are in the selected counterparty
    setCounterPartyInvitationForm({
      counterparty: collaborationCounterpartyId.toString(),
      counterparty_users: [],
    });

    const users = counterpartyUsers.map(
      (u: { uuid: string; email: string }) => ({
        label: u.email,
        value: u.uuid,
      }),
    );
    setUserOptions(users || []);

    setTimeout(() => {
      setIsSendInvitationModalOpen(true);
    }, 200);
  };

  const handleEditManageUserModalClose = () => {
    setIsEditManageUserModalOpen(false);
  };

  const handleEditCounterparty = async (
    form: ICollaborationCounterpartyForm,
  ) => {
    await getCounterpartiesCallAPI();

    const selected = collaborationCounterparties.find(
      (counterparty) => counterparty.counterparty_uuid === form.counterparty,
    );

    if (selected) {
      setCounterpartyForm({
        counterparty: selected.id.toString(),
        counterparty_users: selected.counterparty_user_list.map(
          (user) => user.uuid,
        ),
      });
    }
    setIsEditCounterpartyModalOpen(true);
  };

  const handleEditCounterpartyModalClose = () => {
    setIsEditCounterpartyModalOpen(false);
  };

  const {
    callAPI: getDealCounterpartiesCallAPI,
    loading: isDealCounterpartiesLoading,
    errored: dealCounterpartiesError,
  } = useAPI(getDealCounterparties, {
    initialLoading: true,
  });

  const { callAPI: getCounterpartiesCallAPI, response: counterparties } =
    useAPI(getCounterparties);

  const { callAPI: getSingleCounterpartyCallAPI } = useAPI(
    getSingleCounterparty,
  );

  const {
    callAPI: deleteCollaborationCounterpartyCallAPI,
    loading: isDeletingCounterparty,
  } = useAPI(deleteCollaborationCounterparty);

  const {
    callAPI: addCounterpartyAPI,
    loading: isAddingCounterparty,
    fieldErrors: counterpartyFormErrors,
    setFieldErrors: setCounterpartyFormErrors,
  } = useAPI<ICollaborationCounterparty, ICollaborationCounterpartyFormErrors>(
    addDealCounterparty,
  );

  const {
    callAPI: updateCounterpartyCallAPI,
    loading: isUpdatingCounterparty,
    fieldErrors: updateCounterpartyFormErrors,
    setFieldErrors: setUpdateCounterpartyFormErrors,
  } = useAPI<ICollaborationCounterparty, ICollaborationCounterpartyFormErrors>(
    (
      collaborationUuid: string,
      id: number,
      form: ICollaborationCounterpartyForm,
    ) => updateDealCounterparty(collaborationUuid, id, form),
  );

  const {
    callAPI: sendDealShareEmailCallAPI,
    loading: isSendingDealShareEmail,
    errored: sendDealShareEmailError,
    response: sendDealShareEmailResponse,
  } = useAPI(sendDealShareEmail);

  const handleAddCounterparty = async (
    form: ICollaborationCounterpartyForm,
  ) => {
    if (form.counterparty !== null) {
      handleAddCounterpartyModalClose();
    }

    // Getting all the list of the users in the selected counterparty
    const singleCounterparty = await getSingleCounterpartyCallAPI(
      form.counterparty,
    );

    const users = singleCounterparty?.counterparty_user_list.map(
      (u: { uuid: string; email: string }) => ({
        label: u.email,
        value: u.uuid,
      }),
    );
    setUserOptions(users || []);

    setTimeout(() => {
      // select all users by default when adding a new counterparty
      setCounterpartyForm({
        ...counterpartyForm,
        counterparty_users: users?.map((user) => user.value) || [],
      });
      setIsAddManageUserModalOpen(true);
    }, 200);
  };

  const handleManageAddCounterparty = async (
    counterpartyForm: ICollaborationCounterpartyForm,
  ) => {
    const counterparty = await addCounterpartyAPI(
      collaborationUuid,
      counterpartyForm,
    );
    counterparty && fetchDealCounterparties(collaborationUuid as string);
    return counterparty;
  };

  const handleManageEditCounterparty = async (
    counterpartyForm: ICollaborationCounterpartyForm,
  ) => {
    const updatedForm = _.cloneDeep({ ...counterpartyForm });
    if (updatedForm["counterparty"]) {
      delete updatedForm["counterparty"];
    }

    const counterparty = await updateCounterpartyCallAPI(
      collaborationUuid,
      Number(counterpartyForm.counterparty),
      updatedForm,
    );

    counterparty && fetchDealCounterparties(collaborationUuid as string);
    return counterparty;
  };

  const onDeleteCounterparty = (collaborationCounterpartyId: number) => {
    if (collaborationCounterpartyId === undefined) {
      return;
    }

    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Deal Counterparty",
        description: "Are you sure you want to delete?",
        onConfirm: () => handleDeleteCounterparty(collaborationCounterpartyId),
        loading: isDeletingCounterparty,
      }),
    );
  };

  const handleDeleteCounterparty = async (counterpartyId: number) => {
    const counterparty = await deleteCollaborationCounterpartyCallAPI(
      collaborationUuid,
      counterpartyId,
    );
    counterparty && fetchDealCounterparties(collaborationUuid as string);
  };

  React.useEffect(() => {
    fetchDealCounterparties(collaborationUuid as string);
  }, []);

  const fetchDealCounterparties = async (uuId: string) => {
    getDealCounterpartiesCallAPI(uuId).then((res) => {
      res?.results && setCollaborationCounterparties(res.results);
    });
  };

  React.useEffect(() => {
    if (counterpartyForm.counterparty !== null) {
      getCounterparties();
    }
  }, [counterpartyForm.counterparty]);

  const counterpartyOptions = React.useMemo(() => {
    const collaborationCounterpartyIds = collaborationCounterparties.map(
      (party) => party.counterparty_uuid,
    );

    // Only show the counterparty options that are not already in the collaborationCounterparties
    return (
      counterparties?.results
        ?.filter(
          (counterparty) =>
            !collaborationCounterpartyIds.includes(counterparty.uuid),
        )
        .map((counterparty: ICounterparty) => ({
          label: counterparty.name,
          value: counterparty.uuid,
        })) || []
    );
  }, [counterparties]);

  const transformToGroupedData = (
    collaborationCounterparties: ICollaborationCounterparty[],
  ): IGroupedData<ICounterpartyUser>[] => {
    return collaborationCounterparties.map((collaborationCounterparty) => ({
      id: collaborationCounterparty.id,
      uuid: collaborationCounterparty.counterparty_uuid,
      name: collaborationCounterparty.name,
      type: collaborationCounterparty.counterparty_type,
      items: collaborationCounterparty.counterparty_user_list,
    }));
  };

  const handleSendEmail = async (
    form: ICollaborationCounterpartyForm = counterPartyInvitationForm,
  ) => {
    await sendDealShareEmailCallAPI(
      collaborationUuid as string,
      form.counterparty,
      form.counterparty_users,
    );
    setIsSendInvitationModalOpen(false);
    dispatch(resetConfirmModalPropsAction());

    if (sendDealShareEmailResponse?.success) {
      toast.success(sendDealShareEmailResponse?.message, {
        toastId: "deal-share-email-success",
      });
    }
  };

  const handleSendEmailConfirmation = (title: string, description: string) => {
    dispatch(
      setConfirmModalPropsAction({
        open: true,
        title: title,
        description: description,
        onConfirm: () => handleSendEmail(),
      }),
    );
  };

  return (
    <ViewWrapper
      loading={isDealCounterpartiesLoading}
      error={dealCounterpartiesError}
    >
      {collaborationCounterparties.length !== 0 && (
        <ConditionalProtect type="externalshare">
          <Box className="flex justify-end mb-4 gap-2">
            <Box className="flex justify-end mb-4">
              <Button
                btnType="secondary"
                label="Send this deal to all Counterparties"
                onClick={() =>
                  handleSendEmailConfirmation(
                    "Notify All Counterparty",
                    "Are you sure you want to notify all the counterparty?",
                  )
                }
                startIcon={<ShareIcon />}
              />
            </Box>
            <Box className="flex justify-end mb-4">
              <Button
                btnType="primary"
                label="Add Deal Counterparty"
                onClick={handleAddCounterpartyModalOpen}
                startIcon={<AddIcon />}
              />
            </Box>
          </Box>
        </ConditionalProtect>
      )}

      {currentCollaboration?.status !== "ACT" && (
        <Alert severity="warning">
          Users can not access this deal because the status of this
          collaboration is not "Active". Please change the status to "Active" to
          allow users to access this deal.
        </Alert>
      )}

      {collaborationCounterparties.length === 0 ? (
        <Box className={styles.classes.emptyContainer}>
          <Box className={cn("flex flex-col items-center")}>
            <ConditionalProtect type="externalshare">
              <Button
                canOpenUpgrade
                startIcon={<AddIcon />}
                label="Add Deal Counterparty"
                onClick={handleAddCounterpartyModalOpen}
                btnType="primary"
                className={styles.classes.createBtn}
              />
            </ConditionalProtect>
            <Typography
              variant="body1"
              className={styles.classes.createInfo}
              data-pw="empty-project-valuation-summary-message"
            >
              Deal Share does not exist, click on Add Deal Share to add one.
            </Typography>
          </Box>
        </Box>
      ) : (
        <Paper className="mb-4">
          <GroupedTable<IGroupedData<ICounterpartyUser>, ICounterpartyUser>
            data={transformToGroupedData(collaborationCounterparties)}
            onAction={(...args) => {
              const collaborationCounterparty =
                collaborationCounterparties.find(
                  (counterparty) => counterparty.id === args[0],
                );
              if (collaborationCounterparty != undefined) {
                handleEditManageUserModalOpen(collaborationCounterparty);
              }
            }}
            actionLabel="Manage Users"
            userPermission="externalshare"
            renderGroupHeader={(group, toggleRow, isOpen) => (
              <Box
                className="flex justify-between items-center gap-4"
                key={group.id}
              >
                <Box className="flex items-center gap-2">
                  <IconButton size="small" onClick={toggleRow}>
                    {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                  <Box className="flex items-center gap-2">
                    <Typography>{trimString(group.name, 30)}</Typography>
                    <Chip
                      label={
                        COUNTERPARTY_TYPE[
                          group.type as keyof typeof COUNTERPARTY_TYPE
                        ]
                      }
                    />
                  </Box>
                </Box>

                <ConditionalProtect type="externalshare">
                  <Box className="flex items-center gap-2">
                    <IconButton
                      onClick={() =>
                        handleSendInvitationModalOpen(
                          group.items,
                          group.id as number,
                        )
                      }
                    >
                      <ShareIcon className="text-secondary" />
                    </IconButton>
                    <IconButton
                      onClick={() => onDeleteCounterparty(group.id as number)}
                    >
                      <DeleteOutline className="!text-danger" />
                    </IconButton>
                  </Box>
                </ConditionalProtect>
              </Box>
            )}
            renderColumns={() => [
              <TableCell key="name">Counterparty Name</TableCell>,
              <TableCell key="email">Email</TableCell>,
              <TableCell key="created">Created</TableCell>,
              <TableCell colSpan={2} key="actions" align="right">
                Actions
              </TableCell>,
            ]}
            renderItem={(item) => (
              <React.Fragment>
                <TableCell classes={{ root: "!pl-14" }}>-</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell colSpan={2}>
                  {format(new Date(item.created), "MM/dd/yyyy")}
                </TableCell>
              </React.Fragment>
            )}
            hideActionColumn
          />
        </Paper>
      )}

      <CollaborationCounterpartyFormModal
        open={isAddCounterpartyModalOpen}
        onClose={handleAddCounterpartyModalClose}
        counterpartyOptions={counterpartyOptions}
        form={counterpartyForm}
        setForm={setCounterpartyForm}
        formErrors={counterpartyFormErrors}
        setFormErrors={setCounterpartyFormErrors}
        loading={isAddingCounterparty}
        onConfirm={handleAddCounterparty}
        headerLabel="Add Deal Counterparty"
      />

      <CollaborationCounterpartyFormModal
        open={isEditCounterpartyModalOpen}
        onClose={handleEditCounterpartyModalClose}
        counterpartyOptions={counterpartyOptions}
        form={counterpartyForm}
        setForm={setCounterpartyForm}
        formErrors={counterpartyFormErrors}
        setFormErrors={setCounterpartyFormErrors}
        loading={isAddingCounterparty}
        onConfirm={handleEditCounterparty}
        headerLabel="Edit Deal Counterparty"
      />

      <CollaborationUserManageFormModal
        open={isAddManageUserModalOpen}
        onClose={handleAddManageUserModalClose}
        headerLabel="Manage Users"
        loading={isAddingCounterparty}
        form={counterpartyForm}
        setForm={setCounterpartyForm}
        formErrors={counterpartyFormErrors}
        setFormErrors={setCounterpartyFormErrors}
        userOptions={userOptions}
        onConfirm={handleManageAddCounterparty}
      />

      <CollaborationUserManageFormModal
        open={isEditManageUserModalOpen}
        onClose={handleEditManageUserModalClose}
        headerLabel="Manage Users"
        loading={isUpdatingCounterparty}
        form={counterpartyForm}
        setForm={setCounterpartyForm}
        formErrors={updateCounterpartyFormErrors}
        setFormErrors={setUpdateCounterpartyFormErrors}
        userOptions={userOptions}
        onConfirm={handleManageEditCounterparty}
      />

      <CollaborationUserManageFormModal
        open={isSendInvitationModalOpen}
        onClose={() => setIsSendInvitationModalOpen(false)}
        headerLabel="Select users to send invitation"
        loading={isSendingDealShareEmail}
        form={counterPartyInvitationForm}
        setForm={setCounterPartyInvitationForm}
        formErrors={updateCounterpartyFormErrors}
        setFormErrors={setUpdateCounterpartyFormErrors}
        userOptions={userOptions}
        onConfirm={handleSendEmail}
      />
    </ViewWrapper>
  );
}
